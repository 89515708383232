import { Box } from '@mui/material'
import { useQueryClient } from 'react-query'
import DataGrid from 'components/molecules/DataGrid'
import { getEditedPair } from 'components/molecules/DataGrid/helpers'
import { ERROR, useSnackbar } from 'contexts/SnackbarContext'
import { usePatchAppParameterMutation } from 'domains/app-parameters/mutation'
import { useGetAppParametersQuery } from 'domains/app-parameters/queries'
import { APP_PARAMETERS } from 'domains/app-parameters/templates'
import { replaceOp } from 'helpers/jsonPatch'
import { get } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useTemplatesGrid from './grid'

function AppParameterManager() {
  const { columns } = useTemplatesGrid()
  const patchAppParam = usePatchAppParameterMutation()
  const queryClient = useQueryClient()
  const { popSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const handleRowUpdate = async (next, prev) => {
    const { id } = prev
    const editedPair = getEditedPair(next, prev)

    if (!editedPair) {
      return prev
    }

    const { field, value } = editedPair

    try {
      await patchAppParam.mutateAsync({
        id,
        data: [replaceOp(field, value)],
      })
      queryClient.invalidateQueries({
        queryKey: [APP_PARAMETERS],
      })
      return next
    } catch (error) {
      const message = get(error, `response.data.${field}[0]`, t('error'))
      popSnackbar(message, ERROR)
      return prev
    }
  }

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    popSnackbar(error.message, ERROR)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DataGrid
        id="app-params"
        getEntities={useGetAppParametersQuery}
        sx={{ flex: 1 }}
        columns={columns}
        processRowUpdate={handleRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
      />
    </Box>
  )
}

export default AppParameterManager
