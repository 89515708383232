/* eslint-disable react/jsx-props-no-spreading */
import { Grid } from '@mui/material'
import LoadingScreen from 'components/atoms/LoadingScreen'
import Header from 'components/molecules/Header'
import { useThematicQuery } from 'domains/thematics/queries'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { THEMATICS_PATH } from 'enums/paths'
import LevelsManager from '../LevelsManager'
import CreateForm from './components/CreateForm'
import EditForm from './components/EditForm'
import { Props } from './types'

function ThematicManager({ variant }: Props) {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data: thematic, isLoading } = useThematicQuery(
    { id },
    { enabled: variant === 'edit' && !isEmpty(id) },
  )

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <>
      <Header
        backButton
        backButtonUrl={THEMATICS_PATH}
        title={
          variant === 'create' ? t('thematics-create') : t('thematics-edit')
        }
      />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {variant === 'create' ? (
            <CreateForm />
          ) : (
            <EditForm thematic={thematic} />
          )}
        </Grid>

        {!isLoading && thematic && (
          <Grid item xs={8}>
            <LevelsManager thematic={thematic} />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default ThematicManager
