import { Add, Delete, Edit } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { useConfirm } from 'contexts/ConfirmContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useDeleteEducationalLevelMutation } from 'domains/educationalLevels/mutations'
import { useEducationalLevelsQuery } from 'domains/educationalLevels/queries'
import { EDUCATIONAL_LEVELS } from 'domains/educationalLevels/templates'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import EducationDialog from './EducationDialog'

function EducationLevelManager() {
  const { t } = useTranslation()
  const { confirm } = useConfirm()
  const deleteEducationLevel = useDeleteEducationalLevelMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { data: educationalLevels } = useEducationalLevelsQuery()
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [educationSelected, setEducationSelected] = useState(false)

  const handleDelete = async (id) => {
    await confirm(t('irreversible-action'), t('education-delete-confirmation'))
    await deleteEducationLevel.mutateAsync({ id })
    popSnackbar(t('education-delete-success'), SUCCESS)
    queryClient.invalidateQueries({ queryKey: [EDUCATIONAL_LEVELS] })
  }

  const handleSelectEducation = (id = null) => {
    setEducationSelected(id)
    setOpenEditDialog(true)
  }

  const handleClose = () => {
    setOpenEditDialog(false)
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            width: 'fit-content',
            pr: 6,
            pb: 1,
          }}
        >
          {t('educational-level')}
        </Typography>
        <Button
          sx={{ mb: 1 }}
          startIcon={<Add />}
          onClick={() => handleSelectEducation()}
        >
          {t('add-educational-level')}
        </Button>
      </Box>
      <Box
        sx={{
          height: (theme) => theme.spacing(70),
          overflow: 'hidden',
          backgroundColor: 'primary.main',
          p: 1.5,
          borderRadius: (theme) => theme.spacing(0.5),
        }}
      >
        <Box
          sx={{
            overflowY: 'scroll',
            overflowX: 'unset',
            width: '100%',
            height: '100%',
            '&::-webkit-scrollbar': { display: 'none' },
            boxSizing: 'content-box',
            scrollbarWidth: 'none',
          }}
        >
          {educationalLevels?.data?.map((educationalLevel) => (
            <Box
              key={educationalLevel.id}
              sx={{
                m: 1,
                p: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'common.white',
                borderRadius: (theme) => theme.spacing(0.5),
              }}
            >
              <Typography> {educationalLevel.name}</Typography>
              <Box>
                <IconButton
                  size="small"
                  onClick={() => handleSelectEducation(educationalLevel.id)}
                >
                  <Edit
                    sx={{
                      borderRadius: '50%',
                      bgcolor: 'primary.main',
                      color: 'common.white',
                      border: (theme) =>
                        `3px solid ${theme.palette.primary.main}`,
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleDelete(educationalLevel.id)}
                >
                  <Delete
                    sx={{
                      borderRadius: '50%',
                      bgcolor: 'primary.main',
                      color: 'common.white',
                      border: (theme) =>
                        `3px solid ${theme.palette.primary.main}`,
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <EducationDialog
        open={openEditDialog}
        onClose={handleClose}
        educationId={educationSelected}
      />
    </>
  )
}

export default EducationLevelManager
