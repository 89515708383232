/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { useTownCategoryQuery } from 'domains/town-categories/queries'
import React, { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useTownCategoryForm from './form'

function TownCategoryForm(props) {
  const { onClose, townCategoryId } = props
  const { t } = useTranslation()
  const { form, onSubmit } = useTownCategoryForm(townCategoryId)
  const {
    formState: { isSubmitSuccessful, isDirty },
    handleSubmit,
  } = form
  const { data: townCategory } = useTownCategoryQuery(
    { id: townCategoryId },
    { enabled: !!townCategoryId },
  )

  useEffect(() => {
    if (isSubmitSuccessful) {
      form.reset()
      onClose()
    }
  }, [isSubmitSuccessful])

  useEffect(() => {
    if (townCategory) {
      form.reset(townCategory)
    }
  }, [townCategory])

  return (
    <FormProvider {...form}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              autoFocus
              label={t('name')}
              name="name"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              label={t('minimum')}
              name="populationMinimum"
              fullWidth
              type="number"
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              label={t('maximum')}
              name="populationMaximum"
              fullWidth
              type="number"
              size="small"
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
          <Button size="small" type="submit" disabled={!isDirty}>
            {t('confirm')}
          </Button>
        </Box>
      </Box>
    </FormProvider>
  )
}

export default TownCategoryForm
