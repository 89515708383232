import { Box, CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { MIN_HEIGHT_CARD_CONTENT } from '../../constants'

function CustomerAudience({
  customer = null,
  statistic = null,
  enlarged = false,
}) {
  const { t } = useTranslation()
  if (customer && statistic == null)
    return <CircularProgress color="secondary" />
  return (
    <>
      {!enlarged ? (
        <Typography color="text.secondary" sx={{ display: 'flex', pl: 4.5 }}>
          {t('customer-runs', { runs: statistic?.numberOfRuns })}
        </Typography>
      ) : (
        <Typography color="text.secondary" sx={{ display: 'flex', pl: 4.5 }}>
          {t('customer-runs', { runs: statistic?.globalEnlargedRuns })}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: MIN_HEIGHT_CARD_CONTENT,
        }}
      >
        {!enlarged ? (
          <Typography color="text.secondary" variant="h1">
            {statistic?.customerRunsAudience}
          </Typography>
        ) : (
          <Typography color="primary.dark" variant="h1">
            {statistic?.globalEnlargedAudience}
          </Typography>
        )}
      </Box>
    </>
  )
}

export default CustomerAudience
