import { useMutationWrapper } from 'domains/helpers'
import { OFFER_TYPES, OFFER_TYPE } from './templates'

export function useCreateOfferTypeMutation(options?) {
  return useMutationWrapper('POST', OFFER_TYPES, options)
}

export function useDeleteOfferTypeMutation(options?) {
  return useMutationWrapper('DELETE', OFFER_TYPE, options)
}

export function useUpdateOfferTypeMutation(options?) {
  return useMutationWrapper('PUT', OFFER_TYPE, options)
}
