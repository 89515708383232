import { Box, Tooltip, Typography } from '@mui/material'
import { GridSortModel } from '@mui/x-data-grid'
import { SortDirection } from 'enums/filters'
import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'

function TooltipComponent({ value }) {
  return (
    <Tooltip title={value || ''}>
      <Typography variant="body2" noWrap>
        {value || ''}
      </Typography>
    </Tooltip>
  )
}

const useUserAnswersGrid = () => {
  const { t } = useTranslation()

  const columns = [
    {
      field: 'createDate',
      headerName: t('date'),
      editable: false,
      filterable: false,
      type: 'date',
      width: 160,
      valueGetter: (params) => {
        const date = DateTime.fromISO(params.value).toLocaleString(
          DateTime.DATE_SHORT,
        )
        const hour = DateTime.fromISO(params.value).toLocaleString(
          DateTime.TIME_24_SIMPLE,
        )
        return `${date} - ${hour}`
      },
    },
    {
      field: 'userRun.user.firstName',
      headerName: t('user'),
      editable: false,
      filterable: false,
      width: 140,
      renderCell: (params) => (
        <TooltipComponent value={params.row?.userRun?.user?.firstName} />
      ),
    },
    {
      field: 'userRun.user.email',
      headerName: t('email'),
      editable: false,
      filterable: false,
      width: 150,
      renderCell: (params) => (
        <TooltipComponent value={params.row?.userRun?.user?.email} />
      ),
    },
    {
      field: 'userRun.user.ageRange.name',
      headerName: t('age'),
      editable: false,
      filterable: false,
      width: 90,
      renderCell: (params) => (
        // eslint-disable-next-line no-underscore-dangle
        <TooltipComponent value={params.row?.userRun?.user?._AgeRange} />
      ),
    },
    {
      field: 'userRun.run.name',
      headerName: t('run'),
      editable: false,
      filterable: false,
      width: 200,
      renderCell: (params) => (
        <TooltipComponent value={params.row?.userRun?.run?.name} />
      ),
    },
    {
      field: 'userRun.thematic.name',
      fitlerKey: 'thematic.id',
      headerName: t('thematic'),
      editable: false,
      sortable: false,
      filterable: false,
      width: 200,
      renderCell: (params) => (
        <Tooltip title={params.row?.userRun.thematic.name || null}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: (theme) => theme.spacing(3),
                height: (theme) => theme.spacing(3),
                mr: 1,
                '& img': {
                  width: '100%',
                },
              }}
            >
              <img
                // eslint-disable-next-line no-underscore-dangle
                src={params.row?.userRun?.thematic?.picture?.url}
                alt={params.row?.userRun?.thematic?.name || ''}
              />
            </Box>
            {params.row?.userRun.thematic.name}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: 'question.index',
      headerName: t('question'),
      editable: false,
      filterable: false,
      width: 150,
      renderCell: (params) => (
        <Tooltip title={params.row?.question?.query}>
          <Box>
            {params.row?.question?.index === 0 && (
              <Typography variant="body2">{t('interest')}</Typography>
            )}
            {params.row?.question?.index === 1 && (
              <Typography variant="body2">{t('utility')}</Typography>
            )}
            {params.row?.question?.index === 2 && (
              <Typography variant="body2">{t('free')}</Typography>
            )}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: 'answer',
      headerName: t('answer'),
      editable: false,
      filterable: false,
      flex: 1,
    },
  ]

  const defaultSort: GridSortModel = [
    {
      field: 'createDate',
      sort: SortDirection.DESC,
    },
  ]

  return { columns, defaultSort }
}

export default useUserAnswersGrid
