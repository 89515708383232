import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export const defaultValues = (nextIndex) => {
  return {
    latitude: 0,
    longitude: 0,
    picture: null,
    index: nextIndex,
    isInQRCodeMode: true,
  }
}

function useCreateStepForm(nextIndex) {
  const { t } = useTranslation()

  const validationSchema = Yup.object().shape({
    latitude: Yup.number().required(t('required-field')),
    longitude: Yup.number().required(t('required-field')),
    picture: Yup.object().required(t('required-field')).nullable(),
    index: Yup.number().min(0, t('positive')),
  })

  return useForm({
    defaultValues: defaultValues(nextIndex),
    resolver: yupResolver(validationSchema),
  })
}

export default useCreateStepForm
