import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { CUSTOMER, CUSTOMERS } from './templates'
import { Customer } from './types'

export function useCustomersQuery(
  params?,
  options?,
  filtersPaginationAndSort?,
) {
  return useQueryWrapper<PaginatedType<Customer>>(
    'GET',
    CUSTOMERS,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function useCustomerQuery(params?, options?) {
  return useQueryWrapper<Customer>('GET', CUSTOMER, params, options)
}
