import { Button, Paper, Typography } from '@mui/material'
import blueCardBg from 'assets/svg/blue-card-bg.svg'
import React from 'react'
import { useTranslation } from 'react-i18next'

function CardButton({ Icon, text, clickAction, minHeight }) {
  const { t } = useTranslation()

  return (
    <Button fullWidth sx={{ p: 0, height: '100%' }} onClick={clickAction}>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-around',
          width: '100%',
          bgcolor: 'secondary.light',
          p: 2,
          height: '100%',
          backgroundImage: `url(${blueCardBg})`,
          backgroundSize: 'cover',
          backgroundBlendMode: 'multiply',
          minHeight,
        }}
      >
        <Icon sx={{ height: 94, width: 94, color: 'common.white' }} />
        <Typography textAlign="center" variant="h2">
          {t(text)}
        </Typography>
      </Paper>
    </Button>
  )
}

export default CardButton
