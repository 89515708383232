import { AddCircleOutline, Search } from '@mui/icons-material'
import { Box, Grid, TextField, debounce } from '@mui/material'
import CardButton from 'components/atoms/CardButton'
import Header from 'components/molecules/Header'
import { t } from 'i18next'
import { deburr, filter, lowerCase, times } from 'lodash'

import { usePartnersQuery } from 'domains/partners/queries'
import React, { useCallback, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import PartnerForm from './components/PartnerForm'
import PartnerCard from './components/PartnerCard'
import PartnerCardSkeleton from './components/PartnerCard/Skeleton'
import { MIN_HEIGHT_PARTNER_CARD } from './components/PartnerCard/constants'
import useCreatePartnerForm from './components/PartnerForm/forms/create'

function PartnersManager() {
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)
  const createForm = useCreatePartnerForm()

  const handleClick = () => setOpen(true)
  const { data: partners, isLoading } = usePartnersQuery()
  const filteredPartners = filter(partners?.data ?? [], (partner) =>
    lowerCase(deburr(partner.name)).includes(lowerCase(search)),
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deboucedFilterPartners = useCallback(
    debounce((_search) => {
      setSearch(_search)
    }, 300),
    [],
  )
  return (
    <Box>
      <Header
        title={t('partners')}
        actions={[
          <TextField
            key={0}
            size="small"
            onChange={(e) => deboucedFilterPartners(e.target.value)}
            InputProps={{
              endAdornment: <Search />,
            }}
          />,
        ]}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <CardButton
            Icon={AddCircleOutline}
            text="add-partner"
            clickAction={handleClick}
            minHeight={MIN_HEIGHT_PARTNER_CARD}
          />
        </Grid>
        {isLoading
          ? times(4, (i) => (
              <Grid key={i} item xs={12} sm={6} md={3} lg={2}>
                <PartnerCardSkeleton />
              </Grid>
            ))
          : filteredPartners.map((partner) => (
              <Grid key={partner.id} item xs={12} sm={6} md={3} lg={2}>
                <PartnerCard key={partner.id} partner={partner} />
              </Grid>
            ))}
      </Grid>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...createForm}>
        <PartnerForm open={open} setOpen={setOpen} variant="create" />
      </FormProvider>
    </Box>
  )
}

export default PartnersManager
