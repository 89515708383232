import { yupResolver } from '@hookform/resolvers/yup'
import { FileType } from 'domains/file/enums'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useCreateMissionForm(nextIndex) {
  const { t } = useTranslation()

  const defaultValues = useMemo(
    () => ({
      index: nextIndex,
      beginMissionDescription: '',
      endMissionDescription: '',
      distance: 0,
      duration: 0,
      baseBeginMediaLink: {
        url: '',
        type: FileType.PODCAST,
      },
      baseEndMediaLink: {
        url: '',
        type: FileType.PODCAST,
      },
    }),
    [nextIndex],
  )

  const validationSchema = Yup.object().shape({
    beginMissionDescription: Yup.string()
      .required(t('required-field'))
      .min(3, t('min-3-char'))
      .nullable(),
    endMissionDescription: Yup.string()
      .required(t('required-field'))
      .min(3, t('min-3-char'))
      .nullable(),
    distance: Yup.number().min(0, t('positive')),
    duration: Yup.number().min(0, t('positive')),
    baseBeginMediaLink: Yup.object().shape({
      url: Yup.string()
        .url(t('invalid-url'))
        .required(t('required-field'))
        .nullable(),
    }),
    baseEndMediaLink: Yup.object().shape({
      url: Yup.string()
        .url(t('invalid-url'))
        .required(t('required-field'))
        .nullable(),
    }),
    index: Yup.number().min(0, t('positive')),
  })

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    form.reset({ ...defaultValues, index: nextIndex })
  }, [defaultValues, form, nextIndex])

  return form
}

export default useCreateMissionForm
