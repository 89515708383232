import { Box, Button, List, Popover, Typography } from '@mui/material'
import {
  gridColumnLookupSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid'
import { first, isEmpty } from 'lodash'
import React, { useLayoutEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getEligibleFields, getEligibleOperators } from '../../helpers'
import ToolbarFilterRow from '../ToolbarFilterRow'
import { isErrorFilters } from './helper'

function ToolbarFilterRowsContainer({
  anchorEl,
  setAnchorEl,
  filterUpdate,
  filters,
  append,
  remove,
}) {
  const { t } = useTranslation()
  const open = Boolean(anchorEl)
  const form = useFormContext()
  const { setValue, clearErrors } = form
  const apiRef = useGridApiContext()
  const columns = useGridSelector(apiRef, gridColumnLookupSelector)
  const firstFilterableColumn = first(getEligibleFields(columns))
  const firstFilterableColumnOperator = first(
    getEligibleOperators(firstFilterableColumn?.type, t),
  )

  const handleClose = () => {
    setAnchorEl(null)
  }

  const addNewFilter = () => {
    append({
      op: firstFilterableColumnOperator?.value,
      field: firstFilterableColumn?.field,
      filterKey:
        firstFilterableColumn?.filterKey || firstFilterableColumn?.field,
      value: '',
      multipleValue: [],
      valueLabel: null,
      headerName: null,
    })
  }

  const removeFilter = (index) => {
    if (filters.length === 1) {
      handleClose()
      filterUpdate([])
    }
    remove(index)
  }

  const removeAllFilters = () => {
    handleClose()
    setValue('filters', [])
    filterUpdate([])
    clearErrors()
  }

  useLayoutEffect(() => {
    if (open && filters.length === 0 && !isEmpty(firstFilterableColumn)) {
      addNewFilter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const applyFilters = () => {
    const filtersToApply = form.getValues('filters')
    const isError = isErrorFilters(filtersToApply, form, t)
    if (!isError) {
      filterUpdate(form.getValues('filters'))
    }
  }

  return (
    <Popover
      keepMounted
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiPaper-root': {
          p: 2,
        },
      }}
    >
      {isEmpty(firstFilterableColumn) ? (
        <Typography>{t('no-filterable-columns')}</Typography>
      ) : (
        <List sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 0 }}>
          {filters.map((filter, index) => (
            <ToolbarFilterRow
              key={filter.id}
              filter={filter}
              index={index}
              onRemove={() => removeFilter(index)}
            />
          ))}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
              mt: filters.length > 0 ? 1 : 0,
            }}
          >
            {filters.length > 0 && (
              <Button
                color="error"
                variant="outlined"
                onClick={removeAllFilters}
              >
                {t('delete-all')}
              </Button>
            )}
            <Box>
              <Button variant="outlined" onClick={addNewFilter} sx={{ mr: 1 }}>
                {t('add-filter')}
              </Button>
              <Button variant="contained" onClick={applyFilters}>
                {t('apply')}
              </Button>
            </Box>
          </Box>
        </List>
      )}
    </Popover>
  )
}

export default ToolbarFilterRowsContainer
