import { Operators } from 'enums/filters'
import { FilterOperator } from './components/ToolbarFilterButton/types'

export const getOperators = (t): FilterOperator[] => [
  { value: Operators.CONTAINS, label: t('op-contains') },
  { value: Operators.NOT_CONTAINS, label: t('op-not-contains') },
  { value: Operators.CONTAINS_I, label: t('op-containsi') },
  { value: Operators.NOT_CONTAINS_I, label: t('op-not-containsi') },
  { value: Operators.EQUAL, label: t('op-eq') },
  { value: Operators.EQUAL_I, label: t('op-eqi') },
  { value: Operators.NOT_EQUAL, label: t('op-ne') },
  { value: Operators.LESS_THAN, label: t('op-lt') },
  { value: Operators.LESS_THAN_OR_EQUAL, label: t('op-lte') },
  { value: Operators.GREATER_THAN, label: t('op-gt') },
  { value: Operators.GREATER_THAN_OR_EQUAL, label: t('op-gte') },
  { value: Operators.IN, label: t('op-in') },
  { value: Operators.NOT_IN, label: t('op-not-in') },
  { value: Operators.NULL, label: t('op-null') },
  { value: Operators.NOT_NULL, label: t('op-not-null') },
  { value: Operators.STARTS_WITH, label: t('op-starts-with') },
  { value: Operators.STARTS_WITH_I, label: t('op-starts-withi') },
  { value: Operators.ENDS_WITH, label: t('op-ends-with') },
  { value: Operators.ENDS_WITH_I, label: t('op-ends-withi') },
]

export const MIN_PAGE = 1
export const DEFAULT_PAGE_SIZE = 100
