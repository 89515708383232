import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React from 'react'
import Toolbar from './components/Toolbar'
import useTemplatesGrid from './grid'

function EmailTemplatesManager() {
  const { columns, rows, isLoading } = useTemplatesGrid()

  return (
    <Box
      sx={{
        mt: 2,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DataGrid
        experimentalFeatures={{
          newEditingApi: true,
        }}
        sx={{
          flex: 1,
          borderRadius: (theme) => theme.shape.borderRadius,
          bgcolor: 'white',
          border: 'none',
          p: 2,
          '& .MuiDataGrid-main': {
            height: '65vh',
          },
        }}
        loading={isLoading}
        columns={columns}
        rows={rows}
        onProcessRowUpdateError={null}
        components={{ Toolbar }}
      />
    </Box>
  )
}

export default EmailTemplatesManager
