/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { Box, CircularProgress, IconButton, useTheme } from '@mui/material'
import { ResponsiveLine } from '@nivo/line'
import { useUserMonthGraphQuery } from 'domains/statistics/queries'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import {
  MARGIN_BOTTOM_GRAPH,
  MARGIN_LEFT_GRAPH,
  MARGIN_RIGHT_GRAPH,
  MARGIN_TOP_GRAPH,
} from './constants'
import UserRunsMonthlyTooltip from './tooltip'

function UserRunsMonthly({ customer }) {
  const theme = useTheme()
  const [startMonth, setStartMonth] = useState(
    DateTime.now().startOf('month').minus({ months: 5 }),
  )
  const [endMonth, setEndMonth] = useState(DateTime.now().endOf('month'))

  const { data = [], refetch: refetchUsers } = useUserMonthGraphQuery(
    { id: customer, startMonth, endMonth },
    {},
  )

  useEffect(() => {
    setStartMonth(DateTime.now().startOf('month').minus({ months: 5 }))
    setEndMonth(DateTime.now().endOf('month'))
  }, [customer])

  const addSixMonths = () => {
    setStartMonth(startMonth.plus({ months: 6 }))
    setEndMonth(endMonth.plus({ months: 6 }))
    refetchUsers()
  }

  const removeSixMonths = () => {
    setStartMonth(startMonth.minus({ months: 6 }))
    setEndMonth(endMonth.minus({ months: 6 }))
    refetchUsers()
  }

  if (data == null) return <CircularProgress color="secondary" />

  return (
    <Box>
      <Box
        sx={{
          height: theme.spacing(25),
        }}
      >
        <ResponsiveLine
          data={data}
          // eslint-disable-next-line react/no-unstable-nested-components
          tooltip={({ point }) => <UserRunsMonthlyTooltip point={point} />}
          xFormat="time:%m/%Y"
          xScale={{
            type: 'time',
            format: '%m/%Y',
            precision: 'month',
            useUTC: false,
          }}
          margin={{
            bottom: MARGIN_BOTTOM_GRAPH,
            left: MARGIN_LEFT_GRAPH,
            top: MARGIN_TOP_GRAPH,
            right: MARGIN_RIGHT_GRAPH,
          }}
          yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          axisTop={null}
          axisRight={null}
          axisLeft={{
            tickRotation: 0,
            tickPadding: 10,
            tickSize: 0,
            tickValues: 3,
          }}
          axisBottom={{
            format: '%b %Y',
            tickValues: 'every month',
          }}
          lineWidth={3}
          enableGridX={false}
          colors={data.map((el) => el.color)}
          isInteractive
          useMesh
        />
      </Box>
      <Box
        data-html2canvas-ignore="true"
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <IconButton onClick={removeSixMonths}>
          <ArrowCircleLeftIcon />
        </IconButton>

        <IconButton
          onClick={addSixMonths}
          disabled={
            DateTime.now().startOf('month').get('month') ===
              endMonth.get('month') &&
            DateTime.now().startOf('month').get('year') === endMonth.get('year')
          }
        >
          <ArrowCircleRightIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default UserRunsMonthly
