import { Add } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CustomerOfferType from '../CustomerOfferType'

function CustomerOfferTypesForm({
  addCustomerOfferType,
  removeCustomerOfferType,
  customerOfferTypes,
}) {
  const { t } = useTranslation()
  const handleAddConnection = () => {
    addCustomerOfferType({
      offerType: null,
      startValidityDate: null,
      monthDuration: null,
    })
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2">{t('offer-types')}</Typography>
        <IconButton
          size="small"
          sx={{
            mr: 1,
            width: (theme) => theme.spacing(3),
            height: (theme) => theme.spacing(3),
            border: (theme) => `3px solid ${theme.palette.common.black}`,
            '&:hover': {
              transform: 'scale(1.1)',
            },
          }}
          onClick={handleAddConnection}
        >
          <Add sx={{ color: (theme) => theme.palette.common.black }} />
        </IconButton>
      </Box>
      {!isEmpty(customerOfferTypes) &&
        customerOfferTypes.map((offerType, index) => (
          <CustomerOfferType
            key={offerType.id}
            offerType={offerType}
            index={index}
            removeCustomerOfferType={removeCustomerOfferType}
          />
        ))}
    </Box>
  )
}

export default CustomerOfferTypesForm
