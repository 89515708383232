import { Box, Paper, Typography } from '@mui/material'
import { ReactComponent as Logo } from 'assets/svg/logo-without-name.svg'
import React from 'react'

function CardTemplate({ children = null, title = null }) {
  return (
    <Paper
      sx={{
        bgcolor: 'primary.light',
        p: 1,
        width: 1,
        height: 1,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            '& svg path': {
              fill: (theme) => theme.palette.primary.main,
            },
            '& svg': {
              height: (theme) => theme.spacing(3),
              width: (theme) => theme.spacing(4),
            },
            mr: 0.5,
          }}
        >
          <Logo />
        </Box>
        <Typography color="text.secondary" sx={{ mb: 1 }}>
          {title}
        </Typography>
      </Box>
      {children}
    </Paper>
  )
}

export default CardTemplate
