import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useEditThematicForm(thematic) {
  const { t } = useTranslation()

  const defaultValues = {
    name: '',
    description: '',
    isPrincipal: false,
    levels: [],
    customers: [],
    ...thematic,
    picture: thematic.picture ?? null,
    distance: thematic.distance ?? 0,
    duration: thematic.duration ?? 0,
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required-field')),
    description: Yup.string().required(t('required-field')),
    distance: Yup.number().required(t('required-field')),
    duration: Yup.number().required(t('required-field')),
    isPrincipal: Yup.bool().required(t('required-field')),
    picture: Yup.object().required(t('required-field')).nullable(),
    author: Yup.string().nullable(),
  })

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  return form
}

export default useEditThematicForm
