export enum Operators {
  CONTAINS = 'contains',
  NOT_CONTAINS = 'notContains',
  CONTAINS_I = 'containsi',
  NOT_CONTAINS_I = 'notContainsi',
  EQUAL = 'eq',
  EQUAL_I = 'eqi',
  NOT_EQUAL = 'ne',
  LESS_THAN = 'lt',
  LESS_THAN_OR_EQUAL = 'lte',
  GREATER_THAN = 'gt',
  GREATER_THAN_OR_EQUAL = 'gte',
  NULL = 'null',
  NOT_NULL = 'notNull',
  IN = 'in',
  NOT_IN = 'notIn',
  STARTS_WITH = 'startsWith',
  STARTS_WITH_I = 'startsWithi',
  ENDS_WITH = 'endsWith',
  ENDS_WITH_I = 'endsWithi',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}
