import { ThemeProvider as MUIThemeProvider } from '@mui/material'
import { frFR } from '@mui/material/locale'
import createThemeWithLocaleLib from 'config/theme'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const defaultTheme = createThemeWithLocaleLib(frFR)
function ThemeProvider({ children }) {
  const {
    i18n: { language },
  } = useTranslation()
  const [theme, setTheme] = useState(defaultTheme)

  useEffect(() => {
    switch (language) {
      case 'fr':
        setTheme(createThemeWithLocaleLib(frFR))
        break
      default:
        break
    }
  }, [language])

  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
}

export default ThemeProvider
