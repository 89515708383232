/* eslint-disable react/jsx-props-no-spreading */
import { Delete, Edit } from '@mui/icons-material'
import LandscapeIcon from '@mui/icons-material/Landscape'
import TimelineIcon from '@mui/icons-material/Timeline'
import { alpha, Box, Chip, IconButton, Paper, Typography } from '@mui/material'
import { ERROR, useSnackbar } from 'contexts/SnackbarContext'
import { useRunQuery } from 'domains/runs/queries'
import { formatDistanceToChip } from 'domains/steps/formatters'
import { useDeleteStepMutation } from 'domains/steps/mutations'
import { STEPS } from 'domains/steps/templates'
import React from 'react'
import LazyLoad from 'react-lazyload'
import { useQueryClient } from 'react-query'
import ExportableQRCode from '../ExportableQRCode'

function StyledIconButton(props) {
  return (
    <IconButton size="small" sx={{ color: 'background.default' }} {...props} />
  )
}

function StepCard({ step, handleChange, canDeleteStep, runId }) {
  const queryClient = useQueryClient()
  const deleteStep = useDeleteStepMutation()
  const { popSnackbar } = useSnackbar()
  const { data: run } = useRunQuery({ id: runId })

  const handleDelete = () => {
    deleteStep
      .mutateAsync({ runId, id: step.id })
      .then(() => {
        queryClient.invalidateQueries(STEPS)
      })
      .catch(({ response: { data } }) => popSnackbar(data, ERROR))
  }

  return (
    <LazyLoad height={290}>
      <Paper
        sx={{
          backgroundImage: `url(${step.picture?.url})`,
          backgroundSize: 'cover',
          bgcolor: 'secondary.main',
          borderRadius: 1,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 1,
            bgcolor: (theme) => alpha(theme.palette.common.black, 0.4),
            p: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h1" sx={{ color: 'common.white' }}>
              {step.index}
            </Typography>
            <Box sx={{ flex: 1 }} />
            <StyledIconButton onClick={() => handleChange(step.id)}>
              <Edit />
            </StyledIconButton>
            {canDeleteStep && (
              <StyledIconButton onClick={handleDelete}>
                <Delete />
              </StyledIconButton>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              m: 1,
              height: 120,
            }}
          >
            {step.isInQRCodeMode && <ExportableQRCode run={run} step={step} />}
          </Box>

          <Chip
            sx={{
              bgcolor: 'common.white',
              color: 'common.black',
              width: '100%',
              mt: 1,
              justifyContent: 'space-around',
            }}
            label={formatDistanceToChip(step.latitude)}
            icon={<LandscapeIcon />}
          />
          <Chip
            sx={{
              bgcolor: 'common.white',
              color: 'common.black',
              width: '100%',
              mt: 1,
              justifyContent: 'space-around',
            }}
            label={formatDistanceToChip(step.longitude)}
            icon={<TimelineIcon />}
          />
        </Box>
      </Paper>
    </LazyLoad>
  )
}

export default StepCard
