import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import ControlledUploadFile from 'components/molecules/ControlledUploadFile'
import { useConfirm } from 'contexts/ConfirmContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import {
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
} from 'domains/customers/mutations'
import { CUSTOMERS } from 'domains/customers/templates'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Props } from './types'
import CustomerOfferTypesForm from '../CustomerOfferTypesForm'

function CustomerForm({ open, setOpen, variant, customer = null }: Props) {
  const form = useFormContext()
  const { control } = form
  const { popSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const handleClose = () => setOpen(false)
  const createCustomer = useCreateCustomerMutation()
  const editCustomer = useUpdateCustomerMutation()
  const queryClient = useQueryClient()
  const { confirm } = useConfirm()

  const {
    fields: customerOfferTypes,
    append: addCustomerOfferType,
    remove: removeCustomerOfferType,
  } = useFieldArray({
    control,
    name: 'customerOfferTypes',
  })

  const handleDeleteCustomerOfferType = (index) => {
    confirm(t('customer-offer-type-delete-confirmation')).then(() =>
      removeCustomerOfferType(index),
    )
  }

  const onSubmit = async (values) => {
    if (variant === 'create') {
      return createCustomer.mutateAsync({ data: values }).then(() => {
        setOpen(false)
        popSnackbar(t('customer-create-success'), SUCCESS)
        queryClient.invalidateQueries(CUSTOMERS)
        form.reset()
      })
    }

    if (variant === 'edit') {
      return editCustomer
        .mutateAsync({ id: values.id, data: values })
        .then(() => {
          setOpen(false)
          popSnackbar(t('customer-edit-success'), SUCCESS)
          queryClient.invalidateQueries(CUSTOMERS)
        })
    }

    return null
  }

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
      <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle>
          {variant === 'create' ? t('customer-create') : t('customer-edit')}
        </DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <ControlledTextField fullWidth name="name" label={t('name')} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1.5,
              alignItems: 'center',
            }}
          >
            <ControlledTextField
              type="number"
              size="small"
              label={t('population-number')}
              name="populationNumber"
            />
            <ControlledTextField
              type="number"
              size="small"
              label={t('organisation-number')}
              name="organisationNumber"
            />
          </Box>
          <CustomerOfferTypesForm
            addCustomerOfferType={addCustomerOfferType}
            removeCustomerOfferType={handleDeleteCustomerOfferType}
            customerOfferTypes={customerOfferTypes}
          />
          <ControlledUploadFile name="picture" label={t('picture')} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          <Button variant="contained" type="submit">
            {variant === 'create' ? t('common-save') : t('common-update')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default CustomerForm
