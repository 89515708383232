import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { Settings } from 'luxon'
import { initReactI18next } from 'react-i18next'
import resources from 'locales'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fr',
    fallbackLng: 'fr',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage'],
      caches: ['localStorage', 'cookie'],
      cookieMinutes: 80,
    },
  })

Settings.defaultLocale = 'fr'

export default i18n
