import { useMutationWrapper } from 'domains/helpers'
import {
  AUTHENTICATE,
  REFRESH_TOKEN,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  REVOKE_TOKEN,
  USERS,
  USER,
} from './templates'

export function useAuthenticateMutation(options?) {
  return useMutationWrapper('POST', AUTHENTICATE, options, {
    withCredentials: true,
  })
}

export function useRefreshTokenMutation(options?) {
  return useMutationWrapper('POST', REFRESH_TOKEN, options)
}

export function useRevokeTokenMutation(options?) {
  return useMutationWrapper('POST', REVOKE_TOKEN, options, {
    withCredentials: true,
  })
}

export function useResetPasswordMutation(options?) {
  return useMutationWrapper('POST', RESET_PASSWORD, options)
}

export function useForgotPasswordMutation(options?) {
  return useMutationWrapper('POST', FORGOT_PASSWORD, options)
}

export function useCreateUserMutation(options?) {
  return useMutationWrapper('POST', USERS, options)
}

export function useUpdateUserMutation(options?) {
  return useMutationWrapper('PUT', USER, options)
}

export function useDeleteUserMutation(options?) {
  return useMutationWrapper('DELETE', USER, options)
}
