import { createTheme, responsiveFontSizes } from '@mui/material'
import components from './components'
import palette from './palette'
import typography from './typography'

const createThemeWithLocaleLib = (localeLib) =>
  responsiveFontSizes(
    createTheme(
      {
        components,
        palette,
        typography,
      },
      localeLib,
    ),
  )

export default createThemeWithLocaleLib

// https://mui.com/material-ui/customization/theming/
