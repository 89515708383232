import { Delete } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import { DateTime } from 'luxon'
import ControlledDatePicker from 'components/molecules/ControlledDatePicker'
import ControlledTextField from 'components/molecules/ControlledTextField'
import ControlledAutocomplete from 'components/molecules/ControlledAutocomplete'
import React, { useEffect, useState } from 'react'
import { formatOfferTypeToAutocomplete } from 'domains/offer-types/formatters'
import { useOfferTypesQuery } from 'domains/offer-types/queries'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

function CustomerOfferType({ offerType, index, removeCustomerOfferType }) {
  const { t } = useTranslation()
  const [offerTypesFormatted, setOfferTypesFormatted] = useState([])

  const { data: offerTypes, isLoading: isLoadingOfferTypes } =
    useOfferTypesQuery()

  const { control, setValue } = useFormContext()

  useEffect(() => {
    if (offerTypes?.data) {
      setOfferTypesFormatted(formatOfferTypeToAutocomplete(offerTypes?.data))
    }
  }, [offerTypes])

  const offerTypeControl = useWatch({
    control,
    name: `customerOfferTypes[${index}].offerType`,
  })
  const monthDurationControl = useWatch({
    control,
    name: `customerOfferTypes[${index}].monthDuration`,
  })
  const startDateControl = useWatch({
    control,
    name: `customerOfferTypes[${index}].startValidityDate`,
  })

  useEffect(() => {
    if (offerTypeControl != null && monthDurationControl == null) {
      setValue(
        `customerOfferTypes[${index}].monthDuration`,
        offerTypeControl.defaultMonthDuration,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerTypeControl])

  useEffect(() => {
    if (
      offerTypeControl != null &&
      monthDurationControl != null &&
      startDateControl != null
    ) {
      setValue(
        `customerOfferTypes[${index}].endValidityDate`,
        DateTime.fromISO(startDateControl).plus({
          months: monthDurationControl,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateControl, monthDurationControl])

  return (
    <Grid
      container
      spacing={2}
      key={offerType.id}
      sx={{ alignItems: 'flex-end' }}
    >
      <Grid item xs={12} md={3}>
        <ControlledAutocomplete
          name={`customerOfferTypes[${index}].offerType`}
          TextFieldProps={{ size: 'small', label: t('name') }}
          loading={isLoadingOfferTypes}
          options={offerTypesFormatted}
          getOptionLabel={(option) => option.name}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ControlledDatePicker
          size="small"
          label={t('start-validity-date')}
          name={`customerOfferTypes[${index}].startValidityDate`}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ControlledDatePicker
          type="date"
          disabled
          size="small"
          label={t('end-validity-date')}
          name={`customerOfferTypes[${index}].endValidityDate`}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <ControlledTextField
          type="number"
          size="small"
          label="durée en mois"
          name={`customerOfferTypes[${index}].monthDuration`}
        />
      </Grid>
      <Grid item md={1}>
        <IconButton size="small" onClick={() => removeCustomerOfferType(index)}>
          <Delete sx={{ color: (theme) => theme.palette.common.black }} />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default CustomerOfferType
