import { Delete } from '@mui/icons-material'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { useConfirm } from 'contexts/ConfirmContext'
import { useDeleteMailFusionFieldMutation } from 'domains/mailFusionFields/mutations'
import { useGetMailFusionFieldsQuery } from 'domains/mailFusionFields/queries'
import { isEmpty, upperCase } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useFusionFieldsGrid = () => {
  const { t } = useTranslation()
  const { confirm } = useConfirm()
  const {
    data: mailFusionFields,
    isLoading,
    refetch,
  } = useGetMailFusionFieldsQuery()
  const deleteMailFusionField = useDeleteMailFusionFieldMutation()

  const handleDelete = (id) =>
    confirm(t('confirm-delete-tag'), t('confirm-delete-tag-description'))
      .then(() =>
        deleteMailFusionField.mutate({ id }, { onSuccess: () => refetch() }),
      )
      .catch(() => null)

  const columns = [
    {
      field: 'key',
      headerName: 'Clé',
      width: 150,
      editable: true,
      valueParser: (value) => {
        return upperCase(value)
      },
      valueFormatter: ({ value }) =>
        isEmpty(value) ? '' : `{{${upperCase(value)}}}`,
    },
    {
      field: 'value',
      headerName: 'Valeur',
      width: 150,
      editable: true,
    },
    {
      field: 'isDynamic',
      headerName: 'Dynamique ?',
      width: 100,
      type: 'boolean',
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: ({ row: { id, isDynamic } }) => {
        return [
          <GridActionsCellItem
            icon={<Delete />}
            onClick={() => handleDelete(id)}
            label={t('delete')}
            disabled={isDynamic}
          />,
        ]
      },
    },
  ]

  const rows = mailFusionFields?.data || []

  return { columns, rows, isLoading }
}

export default useFusionFieldsGrid
