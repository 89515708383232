import { Close } from '@mui/icons-material'
import { alpha, Box, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormValues } from '../ToolbarFilterButton/types'

function FiltersTag({ filtersString, remove, filterUpdate }) {
  const { t } = useTranslation()
  const { watch, getValues } = useFormContext<FormValues>()
  const filters = watch('filters')

  const getFiltersList = useMemo(() => {
    return (
      filters && (
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          {filters.map((f, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={`${f.field}-${index}`}
              sx={{
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.7),
                borderRadius: 5,
                color: 'common.white',
                pl: 1,
                display: 'flex',
                alignItems: 'center',
                mr: 1,
                mb: 1,
                pr: 0.5,
                py: 0.5,
              }}
            >
              <Typography variant="caption" sx={{ mr: 1 }}>
                {`${t(f.headerName)} ${t(`op-${f.op}`).toLowerCase()} ${
                  f.valueLabel ?? t(f.value)
                }`}
              </Typography>
              <Tooltip title={t('remove-filter')}>
                <IconButton
                  size="small"
                  onClick={() => {
                    remove(index)
                    filterUpdate(getValues('filters'))
                  }}
                  sx={{
                    bgcolor: 'common.white',
                    height: (theme) => theme.spacing(2.5),
                    width: (theme) => theme.spacing(2.5),
                    '&:hover': {
                      bgcolor: (theme) =>
                        alpha(theme.palette.common.white, 0.7),
                    },
                  }}
                >
                  <Close
                    sx={{
                      color: 'primary.main',
                      height: (theme) => theme.spacing(2.25),
                      width: (theme) => theme.spacing(2.25),
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          ))}
        </Box>
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersString])
  return <Box>{getFiltersList}</Box>
}

export default FiltersTag
