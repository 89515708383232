import { FilterList } from '@mui/icons-material'
import { Badge, IconButton, Tooltip } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ToolbarFilterRowsContainer from '../ToolbarFilterRowsContainer'

function ToolbarFilterButton({
  filterUpdate,
  filters,
  append,
  remove,
  filtersString,
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }
  const { t } = useTranslation()
  const getBadge = useMemo(() => {
    return (
      <Tooltip title={t('filters')}>
        <Badge overlap="circular" badgeContent={filters.length} color="primary">
          <IconButton onClick={handleClick}>
            <FilterList />
          </IconButton>
        </Badge>
      </Tooltip>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersString])

  return (
    <>
      {getBadge}
      <ToolbarFilterRowsContainer
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        filterUpdate={filterUpdate}
        filters={filters}
        append={append}
        remove={remove}
      />
    </>
  )
}

export default ToolbarFilterButton
