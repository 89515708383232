import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useEditThematicLevelForm(level) {
  const { t } = useTranslation()

  const defaultValues = {
    name: '',
    description: '',
    index: -1,
    ...level,
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required-field')),
    description: Yup.string().min(3, t('min-3-char')),
  })

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    form.reset(level)
  }, [form, level])

  return form
}

export default useEditThematicLevelForm
