import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { UserFormProps } from './type'

function useCreateUserForm(props: UserFormProps) {
  const { t } = useTranslation()
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirm: '',
    roles: [],
    gender: null,
    educationalLevel: null,
    town: null,
    customer: null,
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('required-field')),
    lastName: Yup.string().nullable(true),
    email: Yup.string().email(t('invalid-email')).required(t('required-field')),
    gender: Yup.object().nullable().required(t('required-field')),
    password: Yup.string().when(() => {
      if (props.variant === 'create') {
        return Yup.string().required(t('required-field'))
      }
      return Yup.string().nullable(true)
    }),
    passwordConfirm: Yup.string().when(() => {
      if (props.variant === 'create') {
        return Yup.string().oneOf(
          [Yup.ref('password'), null],
          t('must-match-password'),
        )
      }
      return Yup.string().nullable(true)
    }),
  })

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
}

export default useCreateUserForm
