import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AgeRange from '../AgeRange'
import CardTemplate from '../CardTemplate'
import CustomerAudience from '../CustomerAudience'
import GlobalRanks from '../GlobalRanks'
import MonthlyEvolution from '../MonthlyEvolution'
import ThematicsRepartition from '../ThematicsRepartition'
import UserRepartition from '../UserRepartition'
import UserRunsMonthly from '../UserRunsMonthly'

function GridManager({ customer, statistic, customerSelected }) {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(8, 1fr)',
        gap: 2,
      }}
    >
      <Box sx={{ gridRow: 1, gridColumn: 'span 2' }}>
        <CardTemplate title={t('audience')}>
          <CustomerAudience customer={customer} statistic={statistic} />
        </CardTemplate>
      </Box>
      <Box sx={{ gridRow: 1, gridColumn: 'span 2' }}>
        <CardTemplate title={t('audience-enlarged')}>
          {!statistic?.globalStats && (
            <CustomerAudience
              customer={customer}
              statistic={statistic}
              enlarged
            />
          )}
        </CardTemplate>
      </Box>
      <Box sx={{ gridRow: 1, gridColumn: 'span 2' }}>
        <CardTemplate title={t('audience-evolution-monthly')}>
          {!statistic?.globalStats && (
            <MonthlyEvolution statistic={statistic} />
          )}
        </CardTemplate>
      </Box>
      <Box sx={{ gridRow: 2, gridColumn: 'span 6' }}>
        <CardTemplate title={t('number-users-monthly')}>
          <UserRunsMonthly customer={customerSelected?.id} />
        </CardTemplate>
      </Box>
      <Box sx={{ gridRow: '1 / 3', gridColumn: 'span 2' }}>
        <CardTemplate title={t('global-rank-town-category')}>
          <GlobalRanks statistic={statistic} />
        </CardTemplate>
      </Box>
      <Box sx={{ gridRow: 3, gridColumn: 'span 3' }}>
        <CardTemplate title={t('5-best-thematics')}>
          <ThematicsRepartition statistic={statistic} />
        </CardTemplate>
      </Box>
      <Box sx={{ gridRow: 3, gridColumn: 'span 3' }}>
        <CardTemplate title={t('user-repartition')}>
          <UserRepartition statistic={statistic} />
        </CardTemplate>
      </Box>
      <Box sx={{ gridRow: 3, gridColumn: 'span 2' }}>
        <CardTemplate title={t('age-ranges')}>
          <AgeRange statistic={statistic} />
        </CardTemplate>
      </Box>
    </Box>
  )
}

export default GridManager
