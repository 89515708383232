import { AddCircleOutline, Search } from '@mui/icons-material'
import { Box, Grid, TextField } from '@mui/material'
import CardButton from 'components/atoms/CardButton'
import Header from 'components/molecules/Header'
import { useCustomersQuery } from 'domains/customers/queries'
import { debounce, deburr, filter, lowerCase, times } from 'lodash'
import React, { useCallback, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CustomerCard from '../CustomerCard'
import CustomerCardSkeleton from '../CustomerCard/Skeleton'
import CustomerForm from '../CustomerForm'
import useCreateCustomerForm from '../CustomerForm/forms/create'
import { MIN_HEIGHT_CUSTOMER_CARD } from './enums'

function CustomersList() {
  const { t } = useTranslation()
  const [formOpen, setFormOpen] = useState(false)
  const [search, setSearch] = useState('')
  const { data: customers, isLoading } = useCustomersQuery()
  const createForm = useCreateCustomerForm()
  const filteredCustomers = filter(customers?.data ?? [], (customer) =>
    lowerCase(deburr(customer.name)).includes(lowerCase(search)),
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deboucedFilterCustomers = useCallback(
    debounce((_search) => {
      setSearch(_search)
    }, 300),
    [],
  )

  return (
    <Box>
      <Header
        title={t('customers')}
        actions={[
          <TextField
            key={0}
            onChange={(e) => deboucedFilterCustomers(e.target.value)}
            size="small"
            InputProps={{
              endAdornment: <Search />,
            }}
          />,
        ]}
      />
      <Grid container spacing={2}>
        <Grid item sm={12} lg={3}>
          <CardButton
            Icon={AddCircleOutline}
            text="add-customer"
            clickAction={() => setFormOpen(true)}
            minHeight={MIN_HEIGHT_CUSTOMER_CARD}
          />
        </Grid>
        {isLoading
          ? times(4, (i) => (
              <Grid key={i} item sm={12} lg={3}>
                <CustomerCardSkeleton key={i} />
              </Grid>
            ))
          : filteredCustomers.map((customer) => (
              <Grid key={customer.id} item sm={12} lg={3}>
                <CustomerCard customer={customer} />
              </Grid>
            ))}
      </Grid>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...createForm}>
        <CustomerForm open={formOpen} setOpen={setFormOpen} variant="create" />
      </FormProvider>
    </Box>
  )
}

export default CustomersList
