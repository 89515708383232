import Header from 'components/molecules/Header'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

function Users() {
  const { t } = useTranslation()

  return (
    <>
      <Header title={t('users')} />
      <Outlet />
    </>
  )
}

export default Users
