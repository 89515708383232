import React from 'react'
import { CssBaseline } from '@mui/material'
import 'animate.css'
import Snackbar from 'components/organisms/Snackbar'
import QueryClientProvider from 'contexts/QueryClientProvider'
import { SnackbarProvider } from 'contexts/SnackbarContext'
import ThemeProvider from 'contexts/ThemeProvider'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import './config/i18n'
import routes from 'config/routes'
import { AuthProvider } from 'contexts/AuthContext'
import { ConfirmProvider } from 'contexts/ConfirmContext'
import { AxiosInterceptors } from 'contexts/AxiosInterceptors'

function Router() {
  return useRoutes(routes)
}

function Entrypoint() {
  return (
    <ThemeProvider>
      <SnackbarProvider>
        <ConfirmProvider>
          <QueryClientProvider>
            <AuthProvider>
              <BrowserRouter>
                <AxiosInterceptors>
                  <CssBaseline />
                  <Snackbar />
                  <Router />
                </AxiosInterceptors>
              </BrowserRouter>
            </AuthProvider>
          </QueryClientProvider>
        </ConfirmProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default Entrypoint
