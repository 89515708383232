import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useCreateThematicLevelForm(nextIndex) {
  const { t } = useTranslation()

  const defaultValues = useMemo(
    () => ({
      index: nextIndex,
      name: '',
      description: '',
    }),
    [nextIndex],
  )

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required-field')),
    description: Yup.string().min(3, t('min-3-char')),
  })

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    form.reset({ ...defaultValues, index: nextIndex })
  }, [defaultValues, form, nextIndex])

  return form
}

export default useCreateThematicLevelForm
