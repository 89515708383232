import React from 'react'
import {
  QueryClient,
  QueryClientProvider as RQueryClientProvider,
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

function QueryClientProvider({ children }) {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
  })
  return (
    <RQueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </RQueryClientProvider>
  )
}

export default QueryClientProvider
