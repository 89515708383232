import { Box, CircularProgress, useTheme } from '@mui/material'
import { ResponsivePie } from '@nivo/pie'
import React from 'react'
import {
  ANCHOR,
  DIRECTION,
  ITEM_DIRECTION,
  ITEM_HEIGHT,
  ITEM_WIDTH,
  SYMBOL_SHAPE,
  SYMBOL_SIZE,
} from './constants'

function UserRepartition({ statistic = null }) {
  const theme = useTheme()
  if (statistic == null) return <CircularProgress color="secondary" />
  return (
    <Box>
      <Box
        sx={{
          height: theme.spacing(16.25),
        }}
      >
        <ResponsivePie
          data={statistic.genderRepartition}
          innerRadius={0}
          colors={statistic?.genderRepartition?.map((el) => el.color)}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          legends={[
            {
              data: statistic.genderRepartition.map((item, index) => ({
                color: statistic.genderRepartition.map((el) => el.color)[index],
                id: item.id,
                label: `${item.value} % - ${item.label}`,
              })),
              anchor: ANCHOR,
              direction: DIRECTION,
              justify: true,
              itemsSpacing: 5,
              itemWidth: ITEM_WIDTH,
              itemHeight: ITEM_HEIGHT,
              itemTextColor: theme.palette.common.white,
              itemDirection: ITEM_DIRECTION,
              itemOpacity: 1,
              symbolSize: SYMBOL_SIZE,
              symbolShape: SYMBOL_SHAPE,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: theme.palette.common.black,
                  },
                },
              ],
            },
          ]}
        />
      </Box>
    </Box>
  )
}

export default UserRepartition
