import { TypographyOptions } from '@mui/material/styles/createTypography'

const typography: TypographyOptions = {
  fontFamily: ['Poppins'].join(','),
  fontWeightRegular: 500,
  body2: {
    fontSize: '0.875rem',
  },
  h1: {
    fontWeight: 500,
    fontSize: '2.25rem',
  },
  h2: {
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  h3: {
    fontWeight: 500,
    fontSize: '1.4rem',
  },
  h4: {
    fontWeight: 500,
    fontSize: '1.3rem',
  },
  h5: {
    fontWeight: 500,
    fontSize: '1.2rem',
  },
  button: {
    textTransform: 'none',
  },
}

export default typography

// https://mui.com/material-ui/customization/typography/
