import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import ControlledAutocomplete from 'components/molecules/ControlledAutocomplete'
import { ERROR, SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { usePatchCustomerMutation } from 'domains/customers/mutations'
import { CUSTOMERS } from 'domains/customers/templates'
import { usePartnersQuery } from 'domains/partners/queries'
import { replaceOp } from 'helpers/jsonPatch'
import { cloneDeep, filter, find } from 'lodash'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Props } from './types'

function CustomerPartnersForm({ open, setOpen, customer }: Props) {
  const form = useFormContext()
  const { popSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const handleClose = () => setOpen(false)
  const queryClient = useQueryClient()
  const patchCustomer = usePatchCustomerMutation()

  const { data: partners } = usePartnersQuery()
  const partnersNotAlreadySelected = filter(
    partners?.data ?? [],
    (partner) => !find(customer.partners, ['id', partner.id]),
  )

  const onSubmit = async ({ partner }) => {
    const partnersSubmit = cloneDeep(customer.partners)
    partnersSubmit.push(partner)
    return patchCustomer
      .mutateAsync({
        id: customer.id,
        data: [replaceOp('/partners', partnersSubmit)],
      })
      .then(() => {
        popSnackbar(t('add-partner-success'), SUCCESS)
      })
      .catch(() => {
        popSnackbar(t('error'), ERROR)
      })
      .finally(() => {
        form.reset()
        setOpen(false)
        queryClient.invalidateQueries(CUSTOMERS)
      })
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        component="form"
        autoComplete="false"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <DialogTitle>{t('add-partner')}</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <ControlledAutocomplete
            name="partner"
            options={partnersNotAlreadySelected.map(({ name, id }) => ({
              label: name,
              id,
            }))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          <Button variant="contained" type="submit">
            {t('common-save')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default CustomerPartnersForm
