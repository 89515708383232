import Header from 'components/molecules/Header'
import UserParamsManager from 'components/organisms/UserParametersManager'
import React from 'react'
import { useTranslation } from 'react-i18next'

function FieldsParams() {
  const { t } = useTranslation()

  return (
    <>
      <Header title={t('user-fields-parameters-title')} />
      <UserParamsManager />
    </>
  )
}

export default FieldsParams
