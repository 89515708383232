import { useMutationWrapper } from 'domains/helpers'
import { GENDER, GENDERS } from './templates'

export function useCreateGenderMutation(options?) {
  return useMutationWrapper('POST', GENDERS, options)
}

export function useDeleteGenderMutation(options?) {
  return useMutationWrapper('DELETE', GENDER, options)
}

export function useUpdateGenderMutation(options?) {
  return useMutationWrapper('PUT', GENDER, options)
}
