import { Delete, Edit } from '@mui/icons-material'
import { Typography, Box, IconButton } from '@mui/material'
import { useDeleteLevelDefaultMutation } from 'domains/level-defaults/mutations'
import { LEVEL_DEFAULTS } from 'domains/level-defaults/templates'
import React from 'react'
import { useQueryClient } from 'react-query'
import { MIN_HEIGHT_LEVEL_DEFAULT_CARD } from './constants'

function StyledIconButton(props) {
  return (
    <IconButton size="small" sx={{ color: 'background.default' }} {...props} />
  )
}

function LevelDefaultCard({ levelDefault, handleChange }) {
  const deleteLevelDefault = useDeleteLevelDefaultMutation()
  const queryClient = useQueryClient()
  const handleDelete = () => {
    deleteLevelDefault.mutateAsync({ id: levelDefault.id }).then(() => {
      queryClient.invalidateQueries(LEVEL_DEFAULTS)
    })
  }
  return (
    <Box
      sx={{
        bgcolor: 'primary.main',
        position: 'relative',
        minHeight: MIN_HEIGHT_LEVEL_DEFAULT_CARD,
        borderRadius: 1,
        p: 2,
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" sx={{ color: 'common.white' }}>
          {levelDefault?.index}
        </Typography>
        <Box>
          <StyledIconButton onClick={() => handleChange(levelDefault.id)}>
            <Edit />
          </StyledIconButton>

          <StyledIconButton onClick={handleDelete}>
            <Delete />
          </StyledIconButton>
        </Box>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Box
          sx={{
            width: (theme) => theme.spacing(15),
            height: (theme) => theme.spacing(10),
            '& img': {
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            },
            mb: 1,
          }}
        >
          <img src={levelDefault?.picture?.url} alt={levelDefault?.name} />
        </Box>

        <Typography variant="body1">{levelDefault?.name}</Typography>
        <Box sx={{ mt: 1, color: 'common.white' }}>
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            {levelDefault?.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default LevelDefaultCard
