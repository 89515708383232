import { Delete, Edit } from '@mui/icons-material'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { useConfirm } from 'contexts/ConfirmContext'
import { ERROR, SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useDeleteMailTemplateMutation } from 'domains/mailTemplates/mutations'
import { useGetMailTemplatesQuery } from 'domains/mailTemplates/queries'
import { MAIL_TEMPLATES } from 'domains/mailTemplates/templates'
import { EMAIL_MANAGEMENT_TEMPLATES_EDIT_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import urlcat from 'urlcat'

const useTemplatesGrid = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { confirm } = useConfirm()
  const { data: emailTemplates, isLoading } = useGetMailTemplatesQuery()
  const deleteMailTemplate = useDeleteMailTemplateMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const handleDelete = (id) =>
    confirm(t('irreversible-action'), t('confirm-delete-template'))
      .then(() =>
        deleteMailTemplate.mutate(
          { id },
          {
            onSuccess: () => {
              popSnackbar(t('mail-template-delete-success'), SUCCESS)
              queryClient.invalidateQueries(MAIL_TEMPLATES)
            },
            onError: () => {
              popSnackbar(t('error'), ERROR)
            },
          },
        ),
      )
      .catch(() => null)

  const columns = [
    {
      field: 'key',
      headerName: t('key'),
      width: 250,
      editable: true,
    },
    {
      field: 'name',
      headerName: t('template-name'),
      width: 250,
      editable: true,
    },
    {
      field: 'subject',
      headerName: t('subject'),
      flex: 1,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<Delete />}
          onClick={() => handleDelete(id)}
          label={t('delete')}
        />,
        <GridActionsCellItem
          onClick={() =>
            navigate(urlcat(EMAIL_MANAGEMENT_TEMPLATES_EDIT_PATH, { id }))
          }
          icon={<Edit />}
          label={t('edit')}
        />,
      ],
    },
  ]

  const rows = emailTemplates?.data || []

  return { columns, rows, isLoading }
}

export default useTemplatesGrid
