import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { APP_PARAMETER, APP_PARAMETERS } from './templates'
import { AppParameter } from './types'

export function useGetAppParametersQuery(
  params?,
  options?,
  filtersPaginationAndSort?,
) {
  return useQueryWrapper<PaginatedType<AppParameter>>(
    'GET',
    APP_PARAMETERS,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function useGetAppParameterQuery(params?, options?) {
  return useQueryWrapper<AppParameter>('GET', APP_PARAMETER, params, options)
}
