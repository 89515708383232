import { Close } from '@mui/icons-material'
import { alpha, Box, darken, Tooltip } from '@mui/material'
import { useConfirm } from 'contexts/ConfirmContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useUpdateCustomerMutation } from 'domains/customers/mutations'
import { CUSTOMERS } from 'domains/customers/templates'
import { filter } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Props } from './types'

function PartnerLogo({ partner, customer }: Props) {
  const { t } = useTranslation()
  const { popSnackbar } = useSnackbar()
  const { confirm } = useConfirm()
  const queryClient = useQueryClient()
  const updateCustomer = useUpdateCustomerMutation()

  const handleDelete = () => {
    confirm(t('partner-delete-confirm')).then(() =>
      updateCustomer.mutate(
        {
          id: customer.id,
          data: {
            ...customer,
            partners: filter(customer.partners, (p) => p.id !== partner.id),
          },
        },
        {
          onSuccess: () => {
            popSnackbar(t('partner-delete-success'), SUCCESS)
          },
          onSettled: () => {
            queryClient.invalidateQueries(CUSTOMERS)
          },
        },
      ),
    )
  }
  return (
    <Tooltip title={partner.name}>
      <Box
        sx={{ position: 'relative', cursor: 'pointer', mr: 1 }}
        onClick={handleDelete}
      >
        <Box
          component="img"
          sx={{
            height: (theme) => theme.spacing(7),
            width: (theme) => theme.spacing(7),
            objectFit: 'cover',
            bgcolor: 'background.default',
            borderRadius: 1,
            '&:hover': {
              bgcolor: (theme) => darken(theme.palette.background.default, 0.1),
            },
          }}
          src={partner.logo?.url}
          alt={partner.name}
        />
        <Box
          sx={{
            opacity: 0,
            '&:hover': {
              opacity: 1,
            },
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: (theme) => theme.spacing(7),
            width: (theme) => theme.spacing(7),
            top: 0,
            bgcolor: (theme) => alpha(theme.palette.common.black, 0.2),
            borderRadius: 1,
          }}
        >
          <Close sx={{ color: 'common.white', height: 40, width: 40 }} />
        </Box>
      </Box>
    </Tooltip>
  )
}

export default PartnerLogo
