import { Delete, Edit } from '@mui/icons-material'
import { Box, IconButton, Paper, Typography } from '@mui/material'
import { useConfirm } from 'contexts/ConfirmContext'
import { CUSTOMERS } from 'domains/customers/templates'
import { useDeletePartnerMutation } from 'domains/partners/mutations'
import { PARTNERS } from 'domains/partners/templates'
import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import PartnerForm from '../PartnerForm'
import useEditPartnerForm from '../PartnerForm/forms/edit'
import { MIN_HEIGHT_PARTNER_CARD, MIN_SIZE_IMAGE } from './constants'
import { Props } from './types'

function PartnerCard({ partner }: Props) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { confirm } = useConfirm()
  const deletePartner = useDeletePartnerMutation()
  const queryClient = useQueryClient()
  const editForm = useEditPartnerForm(partner)

  const handleEdit = () => setOpen(true)

  const handleDelete = () => {
    confirm(t('partner-delete-confirm')).then(() =>
      deletePartner.mutate(
        { id: partner.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(PARTNERS)
            queryClient.invalidateQueries(CUSTOMERS)
          },
        },
      ),
    )
  }

  return (
    <>
      <Paper
        sx={{
          bgcolor: 'primary.main',
          p: 1,
          minHeight: MIN_HEIGHT_PARTNER_CARD,
          height: 1,
        }}
      >
        <Box sx={{ display: 'flex', mb: 1 }}>
          <Box sx={{ flex: 1 }} />
          <IconButton
            size="small"
            sx={{ color: 'background.default' }}
            onClick={handleEdit}
          >
            <Edit />
          </IconButton>
          <IconButton
            size="small"
            sx={{ color: 'background.default' }}
            onClick={handleDelete}
          >
            <Delete />
          </IconButton>
        </Box>
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          <Box
            component="img"
            src={partner.logo?.url}
            alt={partner.name}
            sx={{
              height: MIN_SIZE_IMAGE,
              width: MIN_SIZE_IMAGE,
              objectFit: 'contain',
              bgcolor: 'background.default',
              borderRadius: 1,
              mb: 1,
            }}
          />
          <Typography align="center">{partner.name}</Typography>
        </Box>
      </Paper>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...editForm}>
        <PartnerForm open={open} setOpen={setOpen} variant="edit" />
      </FormProvider>
    </>
  )
}

export default PartnerCard
