/* eslint-disable no-underscore-dangle */
import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { endFeelingGiven, startFeelingGiven } from '../../formatters'
import SubSectionTitle from '../SubSectionTitle'

function FeelingsDashboard({ userRun }) {
  const { t } = useTranslation()
  return (
    <Box>
      <Typography variant="h2" sx={{ mb: 2 }} fontWeight={700}>
        {t('feelings')}
      </Typography>
      <SubSectionTitle textAlign="left">{t('before-run')}</SubSectionTitle>
      {startFeelingGiven(userRun.feeling) ? (
        <Grid container spacing={2} sx={{ p: 1, mb: 5 }}>
          <Grid item xs={12} md={4}>
            <Paper sx={{ bgcolor: 'primary.main', p: 1 }}>
              <Box>
                <Typography variant="h4" fontWeight="bold">
                  {t('pains')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {userRun.feeling.startPhysicalLevels.map((el) => (
                  <Typography key={el.key}>{t(el.key)}</Typography>
                ))}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ bgcolor: 'primary.main', p: 1 }}>
              <Box>
                <Typography variant="h4" fontWeight="bold">
                  {t('user-feelings')}
                </Typography>
              </Box>
              <Box>{t(userRun.feeling.startEmotion.key)}</Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ bgcolor: 'primary.main', p: 1 }}>
              <Box>
                <Typography variant="h4" fontWeight="bold">
                  {t('thoughts')}
                </Typography>
              </Box>
              <Box>{t(userRun.feeling.startThought.key)}</Box>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Typography sx={{ mt: 2 }}>{t('no-start-feeling')}</Typography>
      )}
      <SubSectionTitle textAlign="left">{t('after-run')}</SubSectionTitle>
      {endFeelingGiven(userRun.feeling) ? (
        <Grid container spacing={2} sx={{ p: 1, mb: 5 }}>
          <Grid item xs={12} md={4}>
            <Paper sx={{ bgcolor: 'primary.main', p: 1 }}>
              <Box>
                <Typography variant="h4" fontWeight="bold">
                  {t('pains')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {userRun.feeling.endPhysicalLevels.map((el) => (
                  <Typography key={el.key}>{t(el.key)}</Typography>
                ))}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ bgcolor: 'primary.main', p: 1 }}>
              <Box>
                <Typography variant="h4" fontWeight="bold">
                  {t('user-feelings')}
                </Typography>
              </Box>
              <Box>{t(userRun.feeling.endEmotion.key)}</Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ bgcolor: 'primary.main', p: 1 }}>
              <Box>
                <Typography variant="h4" fontWeight="bold">
                  {t('thoughts')}
                </Typography>
              </Box>
              <Box>{t(userRun.feeling.endThought.key)}</Box>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Typography sx={{ mt: 2 }}>{t('no-end-feeling')}</Typography>
      )}
    </Box>
  )
}

export default FeelingsDashboard
