import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { LEVEL_DEFAULT, LEVEL_DEFAULTS } from './templates'
import { LevelDefault } from './types'

export function useLevelDefaultssQuery(
  params?,
  options?,
  filtersPaginationAndSort?,
) {
  return useQueryWrapper<PaginatedType<LevelDefault>>(
    'GET',
    LEVEL_DEFAULTS,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function useLevelDefaultQuery(params?, options?) {
  return useQueryWrapper<LevelDefault>('GET', LEVEL_DEFAULT, params, options)
}
