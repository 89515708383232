import Box from '@mui/material/Box'
import React from 'react'
import Drawer from './components/Drawer'
import { DRAWER_WIDTH } from './enums'

function StandardLayout({ children }) {
  const [open, setOpen] = React.useState(false)

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Drawer open={open} setOpen={setOpen} drawerWidth={DRAWER_WIDTH} />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: '100%',
          p: 3,
          mb: 2,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default StandardLayout
