import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useCreateRunForm() {
  const { t } = useTranslation()
  const defaultValues = {
    name: '',
    description: '',
    distance: 0,
    isLoop: false,
    town: null,
    picture: null,
    thematics: [],
    customer: null,
    partners: [],
    isActive: false,
    isPublic: true,
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required-field')),
    description: Yup.string().required(t('required-field')),
    distance: Yup.number().required(t('required-field')),
    isLoop: Yup.bool().required(t('required-field')),
    town: Yup.object().required(t('required-field')).nullable(),
    picture: Yup.object().required(t('required-field')).nullable(),
    customer: Yup.object().required(t('required-field')).nullable(),
    thematics: Yup.array().min(1, t('required-field')),
  })

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
}

export default useCreateRunForm
