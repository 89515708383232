import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import ControlledRadioGroup from 'components/molecules/ControlledRadioGroup'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { ERROR, SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { FileType } from 'domains/file/enums'
import {
  useCreateThematicLevelMissionMutation,
  useUpdateThematicLevelMissionMutation,
} from 'domains/thematics/mutations'
import { THEMATIC_LEVEL_MISSIONS } from 'domains/thematics/templates'
import _ from 'lodash'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Props } from './types'

function MissionForm({
  open,
  setOpen,
  variant,
  thematicId,
  levelId,
  mission = null,
}: Props) {
  const form = useFormContext()
  const { popSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const handleClose = () => setOpen(false)
  const createMission = useCreateThematicLevelMissionMutation()
  const editMission = useUpdateThematicLevelMissionMutation()
  const queryClient = useQueryClient()

  const onSubmit = async (values) => {
    if (variant === 'create') {
      return createMission
        .mutateAsync({ id: thematicId, levelId, data: values })
        .then(() => {
          setOpen(false)
          popSnackbar(t('mission-create-success'), SUCCESS)
          queryClient.invalidateQueries([
            THEMATIC_LEVEL_MISSIONS,
            { id: thematicId, levelId },
          ])
          form.reset()
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.baseBeginMediaLink ||
            error.response?.data?.baseEndMediaLink
          if (!_.isEmpty(errorMessage)) {
            popSnackbar(errorMessage, ERROR)
          }
        })
    }
    if (variant === 'edit') {
      return editMission
        .mutateAsync({
          id: thematicId,
          levelId,
          missionId: mission.id,
          data: values,
        })
        .then(() => {
          setOpen(false)
          popSnackbar(t('mission-edit-success'), SUCCESS)
          queryClient.invalidateQueries([
            THEMATIC_LEVEL_MISSIONS,
            { id: thematicId, levelId },
          ])
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.baseBeginMediaLink ||
            error.response?.data?.baseEndMediaLink
          if (!_.isEmpty(errorMessage)) {
            popSnackbar(errorMessage, ERROR)
          }
        })
    }

    return null
  }

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
      <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle>
          {variant === 'create' ? t('mission-create') : t('mission-edit')}
        </DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ControlledTextField
                type="number"
                name="index"
                label={t('index')}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <ControlledTextField
                type="number"
                name="duration"
                label={t('duration')}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <ControlledTextField
                type="number"
                name="distance"
                label={t('distance')}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Typography>{t('mission-start')}</Typography>
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                fullWidth
                multiline
                minRows={6}
                maxRows={6}
                name="beginMissionDescription"
                label={t('description')}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                name="baseBeginMediaLink.url"
                label={t('media-url')}
                sx={{ mb: 1 }}
                fullWidth
              />
              <ControlledRadioGroup
                name="baseBeginMediaLink.type"
                label={t('media-type')}
                options={[
                  { label: 'Audio', value: FileType.PODCAST },
                  { label: 'Vidéo', value: FileType.VIDEO },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>{t('mission-end')}</Typography>
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                multiline
                minRows={6}
                maxRows={6}
                name="endMissionDescription"
                label={t('description')}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                name="baseEndMediaLink.url"
                label={t('media-url')}
                sx={{ mb: 1 }}
                fullWidth
              />
              <ControlledRadioGroup
                name="baseEndMediaLink.type"
                label={t('media-type')}
                options={[
                  { label: 'Audio', value: FileType.PODCAST },
                  { label: 'Vidéo', value: FileType.VIDEO },
                ]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          <Button variant="contained" type="submit">
            {variant === 'create' ? t('common-save') : t('common-update')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default MissionForm
