import { Box, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'

function UserRunsMonthlyTooltip({ point }) {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        bgcolor: 'common.white',
        borderRadius: 3,
        p: 1,
        zIndex: 10,
        position: 'relative',
        display: 'flex',
        bottom: 0,
      }}
    >
      <Typography display="inline" noWrap sx={{ width: 1 }}>
        {t('tooltip-user-count', {
          userCount: point.data.yFormatted,
          monthDate: DateTime.fromFormat(point.data.xFormatted, 'MM/yyyy')
            .setLocale('fr')
            .toFormat('MMMM yyyy'),
        })}
      </Typography>
    </Box>
  )
}

export default UserRunsMonthlyTooltip
