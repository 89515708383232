import { yupResolver } from '@hookform/resolvers/yup'
import { FileType } from 'domains/file/enums'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useEditMissionForm(mission) {
  const { t } = useTranslation()

  const defaultValues = {
    baseBeginMediaLink: {
      url: mission?.baseBeginMediaLink?.url || '',
      type: mission?.baseBeginMediaLink?.type || FileType.PODCAST,
    },
    baseEndMediaLink: {
      url: mission?.baseEndMediaLink?.url || '',
      type: mission?.baseEndMediaLink?.type || FileType.PODCAST,
    },
    distance: mission?.distance || 0,
    duration: mission?.duration || 0,
    beginMissionDescription: mission?.beginMissionDescription || '',
    endMissionDescription: mission?.endMissionDescription || '',
    index: mission?.index,
  }

  const validationSchema = Yup.object().shape({
    beginMissionDescription: Yup.string()
      .required(t('required-field'))
      .min(3, t('min-3-char'))
      .nullable(),
    endMissionDescription: Yup.string()
      .required(t('required-field'))
      .min(3, t('min-3-char'))
      .nullable(),
    distance: Yup.number().min(0, t('positive')),
    duration: Yup.number().min(0, t('positive')),
    baseBeginMediaLink: Yup.object().shape({
      url: Yup.string()
        .url(t('invalid-url'))
        .required(t('required-field'))
        .nullable(),
    }),
    baseEndMediaLink: Yup.object().shape({
      url: Yup.string()
        .url(t('invalid-url'))
        .required(t('required-field'))
        .nullable(),
    }),
    index: Yup.number().min(0, t('positive')),
  })

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
}

export default useEditMissionForm
