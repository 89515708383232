export const ANCHOR = 'right'
export const DIRECTION = 'column'
export const TRANSLATE_X = 75
export const MARGIN_RIGHT = 200
export const TRANSLATE_Y = 0
export const ITEM_WIDTH = 150
export const ITEM_HEIGHT = 15
export const ITEM_DIRECTION = 'left-to-right'
export const SYMBOL_SIZE = 18
export const SYMBOL_SHAPE = 'circle'
