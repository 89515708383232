import { PaletteOptions } from '@mui/material'

export const black = '#000000'
export const ivory = '#FDF4E4'
export const coral = '#F29B77'
export const coralDark = '#d9805b'
export const coralLight = '#f4af92'
export const blue = '#70BAB5'
export const warning = '#ff1744'
export const success = '#00a152'

const palette: PaletteOptions = {
  primary: {
    main: coral,
    light: coralLight,
    dark: coralDark,
  },
  secondary: {
    main: blue,
  },
  background: {
    default: ivory,
    paper: ivory,
  },
  text: {
    primary: black,
    secondary: ivory,
  },
  common: {
    black,
    white: ivory,
  },
  warning: { main: warning },
  success: { main: success },
}

export default palette

// https://mui.com/material-ui/customization/palette/
