import { isEmpty } from 'lodash'

export function formatOfferTypeToAutocomplete(offerTypes) {
  const collectionFormattedTemp = []
  if (!isEmpty(offerTypes)) {
    offerTypes.map((element) =>
      collectionFormattedTemp.push({
        name: element?.name,
        id: element?.id,
        defaultMonthDuration: element?.defaultMonthDuration,
      }),
    )
  }
  return collectionFormattedTemp
}
