import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const SUCCESS = 'success'
export const WARNING = 'warning'
export const INFO = 'info'
export const ERROR = 'error'

const SnackbarContext = createContext(null)

function SnackbarProvider({ children }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: null,
    message: null,
  })

  const popSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity })
  }

  const closeSnackbar = useCallback(() => {
    setSnackbar({ ...snackbar, open: false })
  }, [snackbar])

  const values = useMemo(
    () => ({
      snackbar,
      popSnackbar,
      closeSnackbar,
    }),
    [closeSnackbar, snackbar],
  )

  return (
    <SnackbarContext.Provider value={values}>
      {children}
    </SnackbarContext.Provider>
  )
}

const useSnackbar = () => {
  const context = useContext(SnackbarContext)
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }
  return context
}

export { SnackbarProvider, useSnackbar }
