/* eslint-disable react/jsx-props-no-spreading */
import { Download } from '@mui/icons-material'
import { alpha, Box, Button } from '@mui/material'
import { get, kebabCase } from 'lodash'
import React, { useRef, useState } from 'react'
import QRCode from 'react-qr-code'
import { saveSvgAsPng } from 'save-svg-as-png'

function ExportableQRCode({ run, step }) {
  const [hover, setHover] = useState(false)
  const buttonRef = useRef()
  const value = get(step, '_UrlQrCode')

  const handlePngExport = () => {
    if (buttonRef) {
      const index = get(step, 'index', 0)
      const fileName = kebabCase(
        `${get(run, 'town.name', '?')}-${get(run, 'name', '?')}-${index}`,
      )

      saveSvgAsPng(get(buttonRef, 'current.firstChild'), `${fileName}.png`, {
        scale: 5,
      })
    }
  }

  return (
    <Button
      ref={buttonRef}
      sx={{ position: 'relative', height: 120, width: 120, p: 0 }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handlePngExport}
    >
      <QRCode size={120} value={value} />
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          opacity: hover ? 1 : 0,
          justifyContent: 'center',
          alignItems: 'center',
          top: 0,
          height: 120,
          width: 120,
          bgcolor: (theme) => alpha(theme.palette.common.black, 0.5),
          transition: 'opacity 0.2s',
        }}
      >
        <Download color="primary" sx={{ fontSize: '5rem' }} />
      </Box>
    </Button>
  )
}

export default ExportableQRCode
