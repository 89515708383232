import { useQueryWrapper } from 'domains/helpers'
import { STATISTIC, USER_MONTHS_GRAPHS } from './templates'
import { NumberOfUserMonthly, Statistic } from './type'

export function useStatisticQuery(params?, options?) {
  return useQueryWrapper<Statistic>('GET', STATISTIC, params, options)
}

export function useUserMonthGraphQuery(params?, options?) {
  return useQueryWrapper<NumberOfUserMonthly[]>(
    'GET',
    USER_MONTHS_GRAPHS,
    params,
    options,
  )
}
