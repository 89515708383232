import { Operators } from 'enums/filters'

export const filterPartners = (customerId) => {
  return [
    {
      field: 'customers',
      filterKey: 'customers.id',
      op: Operators.IN,
      value: customerId,
      multipleValue: [{ value: customerId }],
    },
  ]
}
