import { ArrowDropDown } from '@mui/icons-material'
import { Box, darken, Menu, MenuItem, Typography } from '@mui/material'
import placeholder from 'assets/svg/placeholder.svg'
import { useAuth } from 'contexts/AuthContext'
import { capitalize, get, upperCase } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

function ProfileMenu() {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { user, logout } = useAuth()
  const firstName = user?.firstName || ''
  const lastName = user?.lastName || ''
  const picture = get(user, 'picture', placeholder)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          height: 55,
          borderRadius: 1,
          bgcolor: 'background.default',
          display: 'flex',
          gap: 2,
          justifyContent: 'center',
          alignItems: 'center',
          p: 1,
          cursor: 'pointer',
          '&:hover': {
            bgcolor: (theme) => darken(theme.palette.background.default, 0.1),
          },
        }}
      >
        <img src={picture} alt="placeholder" />
        <Box sx={{ flex: 1, overflow: 'hidden' }}>
          <Typography noWrap sx={{ lineHeight: 1.2 }}>
            {upperCase(lastName)}
          </Typography>
          <Typography noWrap sx={{ lineHeight: 1.2 }}>
            {capitalize(firstName)}
          </Typography>
        </Box>
        <ArrowDropDown />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            bgcolor: 'background.default',
          },
        }}
      >
        <MenuItem onClick={() => logout()}>{t('logout')}</MenuItem>
      </Menu>
    </>
  )
}

export default ProfileMenu
