import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useAddCustomerPartnersForm() {
  const { t } = useTranslation()

  const defaultValues = {
    partner: null,
  }

  const validationSchema = Yup.object().shape({
    partner: Yup.object()
      .shape({
        id: Yup.string(),
      })
      .nullable()
      .required(t('required-field')),
  })

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
}

export default useAddCustomerPartnersForm
