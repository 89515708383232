import { Box, Button, Typography } from '@mui/material'
import { HOME_PATH } from 'enums/paths'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function NotFound() {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        color="primary"
        className="animate__animated animate__fadeIn"
        sx={{ mt: 2, fontWeight: 'bold', fontSize: '10rem' }}
      >
        404
      </Typography>
      <Typography variant="h5">{t('404-exp')}</Typography>
      <Button
        variant="contained"
        sx={{ mt: 4 }}
        component={Link}
        to={HOME_PATH}
      >
        {t('back-home')}
      </Button>
    </Box>
  )
}

export default NotFound
