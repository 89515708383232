/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, Grid } from '@mui/material'
import ControlledAutocomplete from 'components/molecules/ControlledAutocomplete'
import ControlledSwitch from 'components/molecules/ControlledSwitch'
import ControlledTextField from 'components/molecules/ControlledTextField'
import ControlledUploadFile from 'components/molecules/ControlledUploadFile'
import { useCustomersQuery } from 'domains/customers/queries'
import { usePartnersQuery } from 'domains/partners/queries'
import { useThematicsQuery } from 'domains/thematics/queries'
import { useTownsQuery } from 'domains/towns/queries'
import { formatEntityWithNameAndIdToAutocomplete } from 'formatters/autocompleteFormatter'
import {
  formatFiltersToQueryString,
  formatPaginationToQueryString,
} from 'formatters/filters'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatTownsNameWithPostalCode } from '../../formatters'
import { TOWNS_DEFAULT_PAGE, TOWNS_DEFAULT_PAGE_SIZE } from './constants'
import { filterPartners } from './filters'

function Form({ watch, onSubmit, variant }) {
  const { t } = useTranslation()
  const customer = watch('customer')
  const { data: partners, isLoading: partnersLoading } = usePartnersQuery(
    {},
    { enabled: !isEmpty(customer) },
    {
      filters: formatFiltersToQueryString(filterPartners(customer?.id)),
    },
  )
  const [townSearch, setTownSearch] = React.useState('')

  const { data: customers, isLoading: customersLoading } = useCustomersQuery()
  const { data: thematics, isLoading: thematicsLoading } = useThematicsQuery()
  const {
    data: towns,
    isLoading: townsLoading,
    refetch: refetchTowns,
  } = useTownsQuery(
    { search: townSearch },
    {},
    {
      pagination: formatPaginationToQueryString(
        TOWNS_DEFAULT_PAGE,
        TOWNS_DEFAULT_PAGE_SIZE,
      ),
    },
  )

  const [partnersFormatted, setPartnersFormatted] = useState([])
  const [customersFormatted, setCustomersFormatted] = useState([])
  const [thematicsFormatted, setThematicsFormatted] = useState([])
  const [townsFormatted, setTownsFormatted] = useState([])

  useEffect(() => {
    if (partners?.data) {
      setPartnersFormatted(
        formatEntityWithNameAndIdToAutocomplete(partners?.data),
      )
    }
  }, [partners])

  useEffect(() => {
    if (customers?.data) {
      setCustomersFormatted(
        formatEntityWithNameAndIdToAutocomplete(customers?.data),
      )
    }
  }, [customers])

  useEffect(() => {
    if (thematics?.data) {
      setThematicsFormatted(
        formatEntityWithNameAndIdToAutocomplete(thematics?.data),
      )
    }
  }, [thematics])

  useEffect(() => {
    if (towns?.data) {
      setTownsFormatted(formatTownsNameWithPostalCode(towns?.data))
    }
  }, [towns])

  useEffect(() => {
    refetchTowns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [townSearch])

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ControlledTextField
            name="name"
            label={t('name')}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <ControlledSwitch name="isLoop" label={t('loop-run')} />
        </Grid>
        <Grid item xs={12} md={2}>
          <ControlledSwitch name="isActive" label={t('loop-is-active')} />
        </Grid>
        <Grid item xs={12} md={2}>
          <ControlledSwitch name="isPublic" label={t('is-public')} />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="description"
            label={t('description')}
            multiline
            minRows={4}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledTextField
            type="number"
            name="distance"
            label={t('distance')}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledAutocomplete
            name="town"
            TextFieldProps={{ size: 'small', label: t('country') }}
            loading={townsLoading}
            options={townsFormatted}
            onInputChange={(_, value) => {
              setTownSearch(value)
            }}
            filterOptions={(x) => x}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledAutocomplete
            name="customer"
            TextFieldProps={{ size: 'small', label: t('customer') }}
            loading={customersLoading}
            options={customersFormatted}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledAutocomplete
            name="partners"
            TextFieldProps={{ size: 'small', label: t('partners') }}
            loading={partnersLoading}
            options={partnersFormatted}
            multiple
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledAutocomplete
            name="thematics"
            TextFieldProps={{ size: 'small', label: t('thematics') }}
            loading={thematicsLoading}
            options={thematicsFormatted}
            multiple
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledUploadFile name="picture" label={t('picture')} />
        </Grid>
      </Grid>
      <Button variant="contained" color="secondary" type="submit">
        {variant === 'create' ? t('common-save') : t('common-update')}
      </Button>
    </Box>
  )
}

export default Form
