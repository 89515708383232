import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { FormValues } from './components/ToolbarFilterButton/types'

function useFiltersForm(initialValues) {
  const defaultValues: FormValues = {
    filters: initialValues,
    search: '',
  }

  const validationSchema = Yup.object().shape({})

  const form = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      form.reset({ filters: initialValues })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return form
}

export default useFiltersForm
