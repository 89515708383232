import { Box } from '@mui/material'
import ResetPasswordForm from 'components/organisms/ResetPasswordForm'
import React from 'react'

function ResetPassword() {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'primary.main',
      }}
    >
      <ResetPasswordForm />
    </Box>
  )
}

export default ResetPassword
