import { useMutationWrapper } from 'domains/helpers'
import { TOWN_CATEGORY, TOWN_CATEGORIES } from './templates'

export function useCreateTownCategoryMutation(options?) {
  return useMutationWrapper('POST', TOWN_CATEGORIES, options)
}

export function useDeleteTownCategoryMutation(options?) {
  return useMutationWrapper('DELETE', TOWN_CATEGORY, options)
}

export function useUpdateTownCategoryMutation(options?) {
  return useMutationWrapper('PUT', TOWN_CATEGORY, options)
}
