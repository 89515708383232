import { Add, Delete, Edit } from '@mui/icons-material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import { useConfirm } from 'contexts/ConfirmContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useDeleteOfferTypeMutation } from 'domains/offer-types/mutations'
import { useOfferTypesQuery } from 'domains/offer-types/queries'
import { OFFER_TYPES } from 'domains/offer-types/templates'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import OfferTypeDialog from './OfferTypeDialog'

function OfferTypeManager() {
  const { t } = useTranslation()
  const { confirm } = useConfirm()
  const deleteOfferType = useDeleteOfferTypeMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { data: offerTypes } = useOfferTypesQuery()
  const [offerTypeSelected, setOfferTypeSelected] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const handleDelete = async (id) => {
    await confirm(t('irreversible-action'), t('offer-type-delete-confirmation'))
    await deleteOfferType.mutateAsync({ id })
    popSnackbar(t('offer-type-delete-success'), SUCCESS)
    queryClient.invalidateQueries({ queryKey: [OFFER_TYPES] })
  }

  const handleSelectOfferType = (id = null) => {
    setOfferTypeSelected(id)
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            width: 'fit-content',
            pr: 6,
            pb: 1,
          }}
        >
          {t('offer-types')}
        </Typography>
        <Button
          sx={{ mb: 1 }}
          startIcon={<Add />}
          onClick={() => handleSelectOfferType()}
        >
          {t('add-offer-type')}
        </Button>
      </Box>
      <Box
        sx={{
          height: (theme) => theme.spacing(70),
          overflow: 'hidden',
          backgroundColor: 'primary.main',
          p: 1.5,
          borderRadius: (theme) => theme.spacing(0.5),
        }}
      >
        <Box
          sx={{
            overflowY: 'scroll',
            overflowX: 'unset',
            width: '100%',
            height: '100%',
            '&::-webkit-scrollbar': { display: 'none' },
            boxSizing: 'content-box',
            scrollbarWidth: 'none',
          }}
        >
          {offerTypes?.data?.map((offerType) => (
            <Box
              key={offerType.id}
              sx={{
                m: 1,
                p: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'common.white',
                borderRadius: (theme) => theme.spacing(0.5),
              }}
            >
              <Box sx={{ display: 'flex', flex: 1 }}>
                <Typography>
                  {t('name')} : {offerType.name}{' '}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flex: 1 }}>
                <Typography>
                  {t('code')} : {offerType.code}
                </Typography>
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end',
                }}
              >
                <Typography sx={{ mr: 1 }}>
                  {t('validity-date-month')} : {offerType.defaultMonthDuration}
                </Typography>
                <Tooltip
                  title={
                    offerType.dashboardAccess
                      ? t('dashboard-access-active')
                      : t('dashboard-access-disabled')
                  }
                >
                  <DashboardIcon
                    fontSize="small"
                    color={offerType.dashboardAccess ? 'success' : 'error'}
                  />
                </Tooltip>
                <IconButton
                  size="small"
                  onClick={() => handleSelectOfferType(offerType.id)}
                >
                  <Edit
                    sx={{
                      borderRadius: '50%',
                      bgcolor: 'primary.main',
                      color: 'common.white',
                      border: (theme) =>
                        `3px solid ${theme.palette.primary.main}`,
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleDelete(offerType.id)}
                >
                  <Delete
                    sx={{
                      borderRadius: '50%',
                      bgcolor: 'primary.main',
                      color: 'common.white',
                      border: (theme) =>
                        `3px solid ${theme.palette.primary.main}`,
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <OfferTypeDialog
        open={openDialog}
        onClose={handleClose}
        offerTypeId={offerTypeSelected}
      />
    </>
  )
}

export default OfferTypeManager
