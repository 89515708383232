/* eslint-disable no-underscore-dangle */
import { Box, Paper, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { checkNullableDate } from '../../formatters'
import { LOCALE_ZONE, NEEDED_STEPS, TIME_FORMAT } from './constants'

function RunInformations({ userRun }) {
  const { t } = useTranslation()
  return (
    <Paper
      sx={{
        bgcolor: 'primary.main',
        p: 1,
        width: 1,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h2" fontWeight="bold" sx={{ mb: 1 }}>
            {userRun.run.name}
          </Typography>
          <Typography variant="h4">{userRun.run._Town}</Typography>
          <Typography variant="h4">{userRun.run._Customer}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Paper
            sx={{
              minWidth: '15%',
              p: 1,
              mr: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h3">
              {DateTime.fromISO(userRun.startDate).toLocaleString()}
            </Typography>
            <Typography variant="h2" noWrap>
              {DateTime.fromISO(userRun.startDate)
                .setLocale(LOCALE_ZONE)
                .toFormat(TIME_FORMAT)}

              {checkNullableDate(userRun.endDate) &&
                `-${DateTime.fromISO(userRun.endDate)
                  .setLocale(LOCALE_ZONE)
                  .toFormat(TIME_FORMAT)}`}
            </Typography>
          </Paper>
          <Paper sx={{ minWidth: '15%', p: 1, mr: 1 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  width: (theme) => theme.spacing(5),
                  height: (theme) => theme.spacing(4.5),
                  mb: 1,
                  '& img': {
                    width: '100%',
                  },
                }}
              >
                <img
                  src={userRun.thematic?.picture?.url}
                  alt={userRun.thematic?.name}
                />
              </Box>
              <Typography noWrap variant="h2">
                {userRun.thematic?.name}
              </Typography>
            </Box>
          </Paper>
          <Paper sx={{ minWidth: '15%', p: 1, mr: 1 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  width: (theme) => theme.spacing(5),
                  height: (theme) => theme.spacing(4.5),
                  mb: 1,
                  '& img': {
                    width: '100%',
                  },
                }}
              >
                <img
                  src={userRun.level?.picture?.url}
                  alt={userRun.level?.name}
                />
              </Box>
              <Typography variant="h2">{userRun.level?.name}</Typography>
            </Box>
          </Paper>
          <Paper
            sx={{
              minWidth: '15%',
              p: 1,
              mr: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color:
                userRun.userRunSteps.filter((u) => u.dateMarked != null)
                  .length === NEEDED_STEPS
                  ? 'green'
                  : 'red',
            }}
          >
            <Typography variant="h2" sx={{ mb: 1 }}>
              {`${
                userRun.userRunSteps.filter((u) => u.dateMarked != null).length
              }/5`}
            </Typography>
            <Typography variant="h2">{t('steps')}</Typography>
          </Paper>
        </Box>
      </Box>
    </Paper>
  )
}

export default RunInformations
