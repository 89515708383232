import { isEmpty } from 'lodash'

export function formatRoleToAutocomplete(roles) {
  const collectionFormattedTemp = []
  if (!isEmpty(roles)) {
    roles.map((element) =>
      collectionFormattedTemp.push({ label: element?.key, id: element?.id }),
    )
  }
  return collectionFormattedTemp
}
