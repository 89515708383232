import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useForgotPasswordForm() {
  const { t } = useTranslation()
  const isDev = process.env.NODE_ENV === 'development'
  const defaultValues = {
    email: isDev ? 'supervisor@ftel.fr' : '',
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t('required-field')),
  })

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
}

export default useForgotPasswordForm
