import { useMutationWrapper } from 'domains/helpers'
import { EDUCATIONAL_LEVEL, EDUCATIONAL_LEVELS } from './templates'

export function useCreateEducationalLevelMutation(options?) {
  return useMutationWrapper('POST', EDUCATIONAL_LEVELS, options)
}

export function useDeleteEducationalLevelMutation(options?) {
  return useMutationWrapper('DELETE', EDUCATIONAL_LEVEL, options)
}

export function useUpdateEducationalLevelMutation(options?) {
  return useMutationWrapper('PUT', EDUCATIONAL_LEVEL, options)
}
