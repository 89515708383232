import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { TOWN_CATEGORIES, TOWN_CATEGORY } from './templates'
import { TownCategory } from './types'

export function useTownCategoriesQuery(
  params?,
  options?,
  filtersPaginationAndSort?,
) {
  return useQueryWrapper<PaginatedType<TownCategory>>(
    'GET',
    TOWN_CATEGORIES,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function useTownCategoryQuery(params?, options?) {
  return useQueryWrapper<TownCategory>('GET', TOWN_CATEGORY, params, options)
}
