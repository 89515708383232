import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { MAIL_TEMPLATE, MAIL_TEMPLATES } from './templates'
import { MailTemplate } from './types'

export function useGetMailTemplatesQuery(
  params?,
  options?,
  filtersPaginationAndSort?,
) {
  return useQueryWrapper<PaginatedType<MailTemplate>>(
    'GET',
    MAIL_TEMPLATES,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function useGetMailTemplateQuery(params?, options?) {
  return useQueryWrapper<MailTemplate>('GET', MAIL_TEMPLATE, params, options)
}
