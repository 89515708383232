import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { TOWNS } from './templates'
import { Town } from './types'

export function useTownsQuery(params?, options?, filtersPaginationAndSort?) {
  return useQueryWrapper<PaginatedType<Town>>(
    'GET',
    TOWNS,
    params,
    options,
    filtersPaginationAndSort,
  )
}
