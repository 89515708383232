import PrintIcon from '@mui/icons-material/Print'
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  TextField,
} from '@mui/material'
import Header from 'components/molecules/Header'
import { useAuth } from 'contexts/AuthContext'
import { useCustomerQuery, useCustomersQuery } from 'domains/customers/queries'
import { DefaultRole } from 'domains/roles/enums'
import { useStatisticQuery } from 'domains/statistics/queries'
import { LOADER_CIRCULAR_PROGRESS_SIZE } from 'enums/loader'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import GridManager from './components/GridManager'
import { WIDTH_HEADER } from './constants'
import { printDocument } from './helper'

function DashboardManager() {
  const ref = useRef(null)
  const { t } = useTranslation()
  const { user, is } = useAuth()
  const [customerSelected, setCustomerSelected] = useState(null)
  const { data: customers, isLoading } = useCustomersQuery()

  const { data: customer } = useCustomerQuery(
    { id: customerSelected?.id },
    { enabled: !!customerSelected },
  )

  const { data: statistic } = useStatisticQuery({ id: customerSelected?.id })

  useEffect(() => {
    if (user?.customer) {
      setCustomerSelected(user.customer)
    }
  }, [user])
  return (
    <Box
      ref={ref}
      id="dashboard"
      sx={{
        height: 1,
      }}
    >
      <Header
        title={t('dashboard')}
        actions={
          is(DefaultRole.SUPERVISOR) || is(DefaultRole.ADMIN)
            ? [
                <Box key={0} sx={{ display: 'flex' }} id="autocomplete">
                  <IconButton
                    size="large"
                    data-html2canvas-ignore="true"
                    onClick={() => printDocument(customerSelected)}
                  >
                    <PrintIcon />
                  </IconButton>
                  <Autocomplete
                    key={0}
                    onChange={(e, data) => setCustomerSelected(data)}
                    value={customerSelected}
                    options={customers?.data ?? []}
                    getOptionLabel={(option) => option.name || ''}
                    renderInput={(params) => {
                      return (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: WIDTH_HEADER,
                          }}
                        >
                          <TextField
                            size="small"
                            sx={{ ml: 1, width: 1 }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isLoading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={LOADER_CIRCULAR_PROGRESS_SIZE}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'none',
                            }}
                          />
                        </Box>
                      )
                    }}
                  />
                </Box>,
              ]
            : [
                <Box>
                  <IconButton
                    size="large"
                    data-html2canvas-ignore="true"
                    onClick={() => printDocument(customerSelected)}
                  >
                    <PrintIcon />
                  </IconButton>
                </Box>,
              ]
        }
      />
      <GridManager
        customer={customer}
        customerSelected={customerSelected}
        statistic={statistic}
      />
    </Box>
  )
}

export default DashboardManager
