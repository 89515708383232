import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { USER_RUNS, USER_RUN, USER_RUNS_BASE } from './templates'
import { UserRun } from './types'

export function useUserRunsQuery(params?, options?, filtersPaginationAndSort?) {
  return useQueryWrapper<PaginatedType<UserRun>>(
    'GET',
    USER_RUNS,
    params,
    options,
    filtersPaginationAndSort,
  )
}
export function useUserRunsBaseQuery(
  params?,
  options?,
  filtersPaginationAndSort?,
) {
  return useQueryWrapper<PaginatedType<UserRun>>(
    'GET',
    USER_RUNS_BASE,
    params,
    options,
    filtersPaginationAndSort,
  )
}
export function useUserRunQuery(params?, options?) {
  return useQueryWrapper<UserRun>('GET', USER_RUN, params, options)
}
