import { useMutationWrapper } from 'domains/helpers'
import { PARTNER, PARTNERS } from './templates'

export function useCreatePartnerMutation(options?) {
  return useMutationWrapper('POST', PARTNERS, options)
}

export function useUpdatePartnerMutation(options?) {
  return useMutationWrapper('PUT', PARTNER, options)
}

export function usePatchPartnerMutation(options?) {
  return useMutationWrapper('PATCH', PARTNER, options)
}

export function useDeletePartnerMutation(options?) {
  return useMutationWrapper('DELETE', PARTNER, options)
}
