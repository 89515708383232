import {
  Alarm,
  Audiotrack,
  Delete,
  Edit,
  SwapCalls,
  Videocam,
} from '@mui/icons-material'
import { Box, Chip, IconButton, styled, Typography } from '@mui/material'
import { useConfirm } from 'contexts/ConfirmContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { FileType } from 'domains/file/enums'
import {
  formatDistanceToChip,
  formatDurationToChip,
} from 'domains/thematics/formatters'
import { useDeleteThematicLevelMissionMutation } from 'domains/thematics/mutations'
import { THEMATIC_LEVEL_MISSIONS } from 'domains/thematics/templates'
import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import MissionForm from '../MissionForm'
import useEditMissionForm from '../MissionForm/forms/edit'
import { Props } from './types'

const StyledChip = styled(Chip)(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.palette.common.white,
  justifyContent: 'space-between',
}))

function MissionBlockButton({
  thematicId,
  levelId,
  mission,
  missions,
  index,
}: Props) {
  const { t } = useTranslation()
  const { confirm } = useConfirm()
  const deleteMission = useDeleteThematicLevelMissionMutation()
  const queryClient = useQueryClient()
  const editForm = useEditMissionForm(mission)
  const { popSnackbar } = useSnackbar()

  const getIconFromType = (type) => {
    switch (type) {
      case FileType.PICTURE:
      case FileType.PODCAST:
        return <Audiotrack sx={{ color: 'common.black' }} />
      case FileType.VIDEO:
        return <Videocam sx={{ color: 'common.black' }} />
      default:
        return null
    }
  }

  const [formOpen, setFormOpen] = useState(false)

  const handleEdit = () => {
    setFormOpen(true)
  }

  const handleDelete = () => {
    confirm(t('mission-delete-confirm'), t('irreversible-action')).then(() =>
      deleteMission.mutate(
        { id: thematicId, levelId, missionId: mission.id },
        {
          onSuccess: () => {
            popSnackbar(t('mission-delete-success'), SUCCESS)
            queryClient.invalidateQueries([
              THEMATIC_LEVEL_MISSIONS,
              { id: thematicId, levelId },
            ])
          },
        },
      ),
    )
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        color="secondary"
        sx={{
          display: 'flex',
          bgcolor: 'secondary.main',
          height: 140,
          borderRadius: 1,
          p: 1,
          flex: 1,
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'column', width: 100, gap: 1 }}
        >
          <Typography
            sx={{ fontSize: '2.5rem', lineHeight: 1, alignSelf: 'flex-start' }}
          >
            {index}
          </Typography>
          <StyledChip
            icon={<SwapCalls />}
            label={formatDistanceToChip(mission.distance || 0)}
            variant="outlined"
          />
          <StyledChip
            icon={<Alarm />}
            label={formatDurationToChip(mission.duration || 0)}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            px: 1,
            gap: 1,
            height: '100%',
          }}
        >
          <Box sx={{ flex: 1, display: 'flex', gap: 1 }}>
            <Typography
              variant="body2"
              sx={{ width: 75, alignSelf: 'center', textAlign: 'right' }}
            >
              {t('mission-start')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                bgcolor: 'background.default',
                p: 1,
                borderRadius: 1,
                alignItems: 'center',
                flex: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  flex: 1,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 2 /* number of lines to show */,
                  WebkitBoxOrient: 'vertical',
                  textAlign: 'left',
                }}
              >
                {mission.beginMissionDescription}
              </Typography>
              <a href={mission.beginMediaLink} target="_blank" rel="noreferrer">
                {getIconFromType(mission.baseBeginMediaLink?.type)}
              </a>
            </Box>
          </Box>
          <Box sx={{ flex: 1, display: 'flex', gap: 1 }}>
            <Typography
              variant="body2"
              sx={{ width: 75, alignSelf: 'center', textAlign: 'right' }}
            >
              {t('mission-end')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                bgcolor: 'background.default',
                p: 1,
                borderRadius: 1,
                alignItems: 'center',
                flex: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  flex: 1,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 2 /* number of lines to show */,
                  WebkitBoxOrient: 'vertical',
                  textAlign: 'left',
                }}
              >
                {mission.endMissionDescription}
              </Typography>
              <a href={mission.endMediaLink} target="_blank" rel="noreferrer">
                {getIconFromType(mission.baseEndMediaLink?.type)}
              </a>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <IconButton onClick={handleEdit} size="small">
            <Edit sx={{ color: 'common.black' }} />
          </IconButton>
          <IconButton onClick={handleDelete} size="small">
            <Delete sx={{ color: 'common.black' }} />
          </IconButton>
        </Box>
      </Box>
      <FormProvider {...editForm}>
        <MissionForm
          variant="edit"
          mission={mission}
          open={formOpen}
          setOpen={setFormOpen}
          thematicId={thematicId}
          levelId={levelId}
        />
      </FormProvider>
    </Box>
  )
}

export default MissionBlockButton
