import DashboardManager from 'components/organisms/DashboardManager'
import React from 'react'

function Home() {
  return (
    <React.Suspense>
      <DashboardManager />
    </React.Suspense>
  )
}
export default Home
