import { useMutationWrapper } from 'domains/helpers'
import { MAIL_TEMPLATE, MAIL_TEMPLATES } from './templates'

export function useUpdateMailTemplateMutation(options?) {
  return useMutationWrapper('PUT', MAIL_TEMPLATE, options)
}

export function usePatchMailTemplateMutation(options?) {
  return useMutationWrapper('PATCH', MAIL_TEMPLATE, options)
}

export function useDeleteMailTemplateMutation(options?) {
  return useMutationWrapper('DELETE', MAIL_TEMPLATE, options)
}

export function useCreateMailTemplateMutation(options?) {
  return useMutationWrapper('POST', MAIL_TEMPLATES, options)
}
