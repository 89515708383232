/* eslint-disable react/jsx-props-no-spreading */
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useCreateThematicMutation } from 'domains/thematics/mutations'
import { THEMATICS } from 'domains/thematics/templates'
import { THEMATICS_EDIT_PATH } from 'enums/paths'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import urlcat from 'urlcat'
import useCreateThematicForm from '../../forms/create'
import Form from '../Form'

function CreateForm() {
  const { t } = useTranslation()
  const form = useCreateThematicForm()
  const createThematic = useCreateThematicMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const onSubmit = (values) => {
    createThematic.mutate(
      { data: values },
      {
        onSuccess: ({ id }) => {
          popSnackbar(t('thematic-create-success'), SUCCESS)
          queryClient.invalidateQueries(THEMATICS)
          navigate(urlcat(THEMATICS_EDIT_PATH, { id }))
        },
      },
    )
  }

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)} variant="create" />
    </FormProvider>
  )
}

export default CreateForm
