import { Construction } from '@mui/icons-material'
import { alpha, Box, Typography } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

function UnderConstruction() {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        flex: 1,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: (theme) =>
          `repeating-linear-gradient(45deg,${
            theme.palette.background.default
          },${theme.palette.background.default} 20px,${alpha(
            theme.palette.primary.main,
            0.2,
          )} 20px,${alpha(theme.palette.primary.main, 0.2)} 40px)`,
      }}
    >
      <Construction
        color="primary"
        sx={{ height: 120, width: 120 }}
        className="animate__animated animate__infinite animate__tada"
      />
      <Typography variant="h5" sx={{ mt: 2, textAlign: 'center' }}>
        {t('under-construction-exp')}
      </Typography>
    </Box>
  )
}

export default UnderConstruction
