import { Add, Delete, Edit } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { useConfirm } from 'contexts/ConfirmContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useDeleteTownCategoryMutation } from 'domains/town-categories/mutations'
import { useTownCategoriesQuery } from 'domains/town-categories/queries'
import { TOWN_CATEGORIES } from 'domains/town-categories/templates'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import TownCategoryDialog from './TownCategoryDialog'

function TownCategoryManager() {
  const { t } = useTranslation()
  const { confirm } = useConfirm()
  const deleteCategory = useDeleteTownCategoryMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { data: categories } = useTownCategoriesQuery()
  const [categorySelected, setCategorySelected] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const handleDelete = async (id) => {
    await confirm(
      t('irreversible-action'),
      t('town-category-delete-confirmation'),
    )
    await deleteCategory.mutateAsync({ id })
    popSnackbar(t('town-category-delete-success'), SUCCESS)
    queryClient.invalidateQueries({ queryKey: [TOWN_CATEGORIES] })
  }

  const handleSelectCategory = (id = null) => {
    setCategorySelected(id)
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            width: 'fit-content',
            pr: 6,
            pb: 1,
          }}
        >
          {t('town-categories')}
        </Typography>
        <Button
          sx={{ mb: 1 }}
          startIcon={<Add />}
          onClick={() => handleSelectCategory()}
        >
          {t('add-category')}
        </Button>
      </Box>
      <Box
        sx={{
          height: (theme) => theme.spacing(70),
          overflow: 'hidden',
          backgroundColor: 'primary.main',
          p: 1.5,
          borderRadius: (theme) => theme.spacing(0.5),
        }}
      >
        <Box
          sx={{
            overflowY: 'scroll',
            overflowX: 'unset',
            width: '100%',
            height: '100%',
            '&::-webkit-scrollbar': { display: 'none' },
            boxSizing: 'content-box',
            scrollbarWidth: 'none',
          }}
        >
          {categories?.data?.map((cat) => (
            <Box
              key={cat.id}
              sx={{
                m: 1,
                p: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'common.white',
                borderRadius: (theme) => theme.spacing(0.5),
              }}
            >
              <Box sx={{ display: 'flex', flex: 1 }}>
                <Typography>
                  {t('name')} : {cat.name}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                <Typography sx={{ mr: 2 }}>
                  {t('minimum')} : {cat.populationMinimum} {t('population')}
                </Typography>
                <Typography>
                  {t('maximum')} : {cat.populationMaximum} {t('population')}
                </Typography>
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleSelectCategory(cat.id)}
                >
                  <Edit
                    sx={{
                      borderRadius: '50%',
                      bgcolor: 'primary.main',
                      color: 'common.white',
                      border: (theme) =>
                        `3px solid ${theme.palette.primary.main}`,
                    }}
                  />
                </IconButton>
                <IconButton size="small" onClick={() => handleDelete(cat.id)}>
                  <Delete
                    sx={{
                      borderRadius: '50%',
                      bgcolor: 'primary.main',
                      color: 'common.white',
                      border: (theme) =>
                        `3px solid ${theme.palette.primary.main}`,
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <TownCategoryDialog
        open={openDialog}
        onClose={handleClose}
        townCategoryId={categorySelected}
      />
    </>
  )
}

export default TownCategoryManager
