import { useMutationWrapper } from 'domains/helpers'
import { LEVEL_DEFAULT, LEVEL_DEFAULTS } from './templates'

export function useCreateLevelDefaultMutation(options?) {
  return useMutationWrapper('POST', LEVEL_DEFAULTS, options)
}

export function useUpdateLevelDefaultMutation(options?) {
  return useMutationWrapper('PUT', LEVEL_DEFAULT, options)
}

export function useDeleteLevelDefaultMutation(options?) {
  return useMutationWrapper('DELETE', LEVEL_DEFAULT, options)
}
