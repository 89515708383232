import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { MAIL_FUSION_FIELDS } from './templates'
import { MailFusionField } from './types'

export function useGetMailFusionFieldsQuery(
  params?,
  options?,
  filtersPaginationAndSort?,
) {
  return useQueryWrapper<PaginatedType<MailFusionField>>(
    'GET',
    MAIL_FUSION_FIELDS,
    params,
    options,
    filtersPaginationAndSort,
  )
}
