import { GridSortModel } from '@mui/x-data-grid'
import {
  formatFiltersToQueryString,
  formatPaginationToQueryString,
  formatSortingToQueryString,
} from 'formatters/filters'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { DEFAULT_PAGE_SIZE, MIN_PAGE } from './enums'

const useGrid = (
  getEntities,
  searchParams = {},
  filtersFromLS,
  defaultSort: GridSortModel = [],
  baseFilters = '',
  options,
) => {
  const [currentPage, setCurrentPage] = useState(MIN_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [filters, setFilters] = useState(
    formatFiltersToQueryString(filtersFromLS),
  )
  const [search, setSearch] = useState('')
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSort)
  const {
    data: entitiesPaginated,
    isLoading,
    refetch,
  } = getEntities(
    {
      ...searchParams,
      search,
    },
    options,
    {
      filters: `${filters}${isEmpty(filters) ? '' : '&'}${baseFilters}`,
      pagination: formatPaginationToQueryString(currentPage, pageSize),
      sort: formatSortingToQueryString(sortModel),
    },
  )
  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, currentPage, pageSize, sortModel])

  const rows = entitiesPaginated?.data || []

  return {
    rows,
    loading: isLoading,
    filters,
    setFilters,
    setSearch,
    setCurrentPage,
    setPageSize,
    currentPage,
    pageSize,
    search,
    refetch,
    total: entitiesPaginated?.meta?.pagination?.total,
    sortModel,
    setSortModel,
  }
}

export default useGrid
