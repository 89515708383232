import { Close } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EducationalLevelForm from '../Form'

function EducationDialog(props) {
  const { open, onClose, educationId } = props
  const { t } = useTranslation()
  const isCreation = isEmpty(educationId)

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {isCreation ? t('add-education') : t('update-education')}
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ '&.MuiDialogContent-root': { pt: 1 } }}>
        <EducationalLevelForm onClose={onClose} educationId={educationId} />
      </DialogContent>
    </Dialog>
  )
}
export default EducationDialog
