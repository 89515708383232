import { useMutationWrapper } from 'domains/helpers'
import {
  THEMATIC,
  THEMATICS,
  THEMATIC_LEVEL,
  THEMATIC_LEVELS,
  THEMATIC_LEVEL_MISSION,
  THEMATIC_LEVEL_MISSIONS,
} from './templates'

export function useCreateThematicMutation(options?) {
  return useMutationWrapper('POST', THEMATICS, options)
}

export function useUpdateThematicMutation(options?) {
  return useMutationWrapper('PUT', THEMATIC, options)
}

export function usePatchThematicMutation(options?) {
  return useMutationWrapper('PATCH', THEMATIC, options)
}

export function useDeleteThematicMutation(options?) {
  return useMutationWrapper('DELETE', THEMATIC, options)
}

export function useCreateThematicLevelMutation(options?) {
  return useMutationWrapper('POST', THEMATIC_LEVELS, options)
}

export function useUpdateThematicLevelMutation(options?) {
  return useMutationWrapper('PUT', THEMATIC_LEVEL, options)
}

export function useDeleteThematicLevelMutation(options?) {
  return useMutationWrapper('DELETE', THEMATIC_LEVEL, options)
}

export function useCreateThematicLevelMissionMutation(options?) {
  return useMutationWrapper('POST', THEMATIC_LEVEL_MISSIONS, options)
}

export function useUpdateThematicLevelMissionMutation(options?) {
  return useMutationWrapper('PUT', THEMATIC_LEVEL_MISSION, options)
}

export function usePatchThematicLevelMissionMutation(options?) {
  return useMutationWrapper('PATCH', THEMATIC_LEVEL_MISSION, options)
}

export function useDeleteThematicLevelMissionMutation(options?) {
  return useMutationWrapper('DELETE', THEMATIC_LEVEL_MISSION, options)
}
