import { Refresh } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

function ToolbarRefreshButton({ onRefresh }) {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('refresh')}>
      <IconButton onClick={onRefresh}>
        <Refresh />
      </IconButton>
    </Tooltip>
  )
}

export default ToolbarRefreshButton
