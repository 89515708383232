import Header from 'components/molecules/Header'
import AppParameterManager from 'components/organisms/AppParameterManager'
import React from 'react'
import { useTranslation } from 'react-i18next'

function AppParams() {
  const { t } = useTranslation()

  return (
    <>
      <Header title={t('app-params')} />
      <AppParameterManager />
    </>
  )
}

export default AppParams
