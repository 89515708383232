export const HOME_PATH = '/'
export const LOGIN_PATH = '/login'
export const LOGOUT_PATH = '/logout'
export const RESET_PASSWORD_PATH = '/reset-password/:key'
export const FORGOT_PASSWORD_PATH = '/forgot-password'
export const FORGOT_PASSWORD_SUCCESS_PATH = '/forgot-password/success'
export const EMAIL_MANAGEMENT_PATH = '/email-management'
export const EMAIL_MANAGEMENT_TEMPLATES_PATH = '/email-management/templates'
export const EMAIL_MANAGEMENT_TEMPLATES_CREATE_PATH =
  '/email-management/templates/create'
export const EMAIL_MANAGEMENT_TEMPLATES_EDIT_PATH =
  '/email-management/templates/:id/edit'
export const EMAIL_MANAGEMENT_LAYOUT_PATH = '/email-management/layout'
export const EMAIL_MANAGEMENT_FUSION_FIELDS_PATH =
  '/email-management/fusion-fields'
export const EMAIL_MANAGEMENT_FUSION_FIELDS_CREATE_PATH =
  '/email-management/fusion-fields/create'
export const EMAIL_MANAGEMENT_TEST_PATH = '/email-management/test'
export const INTERNATIONALIZATION_PATH = '/internationalization'
export const CUSTOMERS_PATH = '/customers'
export const PARTNERS_PATH = '/partners'
export const USERS_PATH = '/users'
export const USERS_CREATE_PATH = '/users/create'
export const USERS_EDIT_PATH = '/users/:id/edit'
export const APP_PARAMS_PATH = '/app-params'
export const APP_PARAMS_PARAMETER_PATH = '/app-params/app-parameters'
export const APP_PARAMS_PARAMETER_EDIT_PATH =
  '/app-params/app-parameters/:id/edit'
export const THEMATICS_PATH = '/thematics'
export const THEMATICS_CREATE_PATH = '/thematics/create'
export const THEMATICS_EDIT_PATH = '/thematics/edit/:id'
export const RUNS_PATH = '/runs'
export const RUNS_CREATE_PATH = '/runs/create'
export const RUNS_EDIT_PATH = '/runs/edit/:id'
export const LEVEL_DEFAULTS_PATH = '/level-defaults'
export const QUESTIONS_ANSWERS_PATH = '/questions-answers'
export const USER_FIELDS_PARAMETERS_PATH = '/user-fields-parameter'
export const HISTORIC_PATH = '/historic'
export const RUN_DASHBOARD_PATH = '/historic/:id'
