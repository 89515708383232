import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { USER_ANSWERS } from './templates'
import { UserAnswer } from './types'

export function useUserAnswersQuery(
  params?,
  options?,
  filtersPaginationAndSort?,
) {
  return useQueryWrapper<PaginatedType<UserAnswer>>(
    'GET',
    USER_ANSWERS,
    params,
    options,
    filtersPaginationAndSort,
  )
}
