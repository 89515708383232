import { yupResolver } from '@hookform/resolvers/yup'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import {
  useCreateGenderMutation,
  useUpdateGenderMutation,
} from 'domains/gender/mutations'
import { GENDERS } from 'domains/gender/templates'
import { isEmpty } from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import * as Yup from 'yup'

function useGenderForm(genderId) {
  const { t } = useTranslation()
  const isCreation = isEmpty(genderId)
  const { popSnackbar } = useSnackbar()
  const createGender = useCreateGenderMutation()
  const updateGender = useUpdateGenderMutation()
  const queryClient = useQueryClient()

  const defaultValues = {
    name: '',
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required-field')),
  })

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async (values) => {
    if (isCreation) {
      await createGender.mutateAsync({ data: values })
      popSnackbar(t('user-education-create-success'), SUCCESS)
      form.reset(defaultValues, { keepIsSubmitted: false })
      queryClient.invalidateQueries({ queryKey: [GENDERS] })
    } else {
      await updateGender.mutateAsync({ id: genderId, data: values })
      popSnackbar(t('education-update-success'), SUCCESS)
      form.reset(defaultValues, { keepIsSubmitted: false })
      queryClient.invalidateQueries({ queryKey: [GENDERS] })
    }
  }

  return { form, onSubmit }
}

export default useGenderForm
