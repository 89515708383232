import { AddCircleOutline, Search } from '@mui/icons-material'
import { Grid, TextField } from '@mui/material'
import CardButton from 'components/atoms/CardButton'
import Header from 'components/molecules/Header'
import { useRunsQuery } from 'domains/runs/queries'
import { RUNS_CREATE_PATH } from 'enums/paths'
import { debounce } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import RunCard from './components/RunCard'
import { MIN_HEIGHT_RUN_CARD } from './components/RunCard/constants'

function RunsList() {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const { data: runs, isLoading } = useRunsQuery({ search })
  const navigate = useNavigate()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deboucedFilterRuns = useCallback(
    debounce((_search) => {
      setSearch(_search)
    }, 300),
    [],
  )

  return (
    <>
      <Header
        title={t('runs')}
        actions={[
          <TextField
            key={0}
            onChange={(e) => deboucedFilterRuns(e.target.value)}
            size="small"
            InputProps={{
              endAdornment: <Search />,
            }}
          />,
        ]}
      />
      <Grid container spacing={2}>
        <Grid item sm={12} md={4} lg={3}>
          <CardButton
            Icon={AddCircleOutline}
            text="add-run"
            clickAction={() => navigate(RUNS_CREATE_PATH)}
            minHeight={MIN_HEIGHT_RUN_CARD}
          />
        </Grid>
        {!isLoading &&
          runs?.data &&
          runs?.data?.map((run, i) => (
            <Grid key={run.id || i} item sm={12} md={4} lg={3}>
              <RunCard run={run} />
            </Grid>
          ))}
      </Grid>
    </>
  )
}

export default RunsList
