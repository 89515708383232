import { ArrowBack } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'
import ControlledAutocomplete from 'components/molecules/ControlledAutocomplete'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useCustomersQuery } from 'domains/customers/queries'
import { formatEducationLevelsToAutocomplete } from 'domains/educationalLevels/formatters'
import { useEducationalLevelsQuery } from 'domains/educationalLevels/queries'
import { formatGenderToAutocomplete } from 'domains/gender/formatters'
import { useGendersQuery } from 'domains/gender/queries'
import { formatRoleToAutocomplete } from 'domains/roles/formatters'
import { useRolesQuery } from 'domains/roles/queries'
import { useTownsQuery } from 'domains/towns/queries'
import { useCreateUserMutation } from 'domains/users/mutations'
import { USER, USERS } from 'domains/users/templates'
import { USERS_PATH } from 'enums/paths'
import { formatEntityWithNameAndIdToAutocomplete } from 'formatters/autocompleteFormatter'
import { formatPaginationToQueryString } from 'formatters/filters'
import { filter, find, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { FormProvider, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { DefaultRole } from '../../../domains/roles/enums'
import { formatTownsNameWithPostalCode } from '../RunsForm/formatters'
import {
  TOWNS_DEFAULT_PAGE,
  TOWNS_DEFAULT_PAGE_SIZE,
} from '../UsersManager/components/Edit/constants'
import useCreateUserForm from './form'

export const CREATE = 'create'

function UsersForm() {
  const { t } = useTranslation()
  const { popSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const form = useCreateUserForm({ variant: 'create' })
  const { control } = form
  const createUser = useCreateUserMutation()
  const queryClient = useQueryClient()
  const { data: customers, isLoading: customersLoading } = useCustomersQuery()
  const [customersFormatted, setCustomersFormatted] = useState([])
  const [townSearch, setTownSearch] = React.useState('')
  const { data: educationalLevel, isLoading: isLoadingEducation } =
    useEducationalLevelsQuery()
  const {
    data: towns,
    isLoading: townsLoading,
    refetch: refetchTowns,
  } = useTownsQuery(
    { search: townSearch },
    {},
    {
      pagination: formatPaginationToQueryString(
        TOWNS_DEFAULT_PAGE,
        TOWNS_DEFAULT_PAGE_SIZE,
      ),
    },
  )
  const { data: roles, isLoading: isLoadingRoles } = useRolesQuery()
  const { data: genders, isLoading: isLoadingGenders } = useGendersQuery()
  const [rolesFormatted, setRolesFormatted] = useState([])
  const [gendersFormatted, setGendersFormatted] = useState([])
  const [townsFormatted, setTownsFormatted] = useState([])
  const [educationLevelsFormatted, setEducationLevelsFormatted] = useState([])

  const rolesAlreadySelected = form.watch('roles')
  const rolesNotAlreadySelected = filter(
    rolesFormatted,
    (role) => !find(rolesAlreadySelected, ['id', role.id]),
  )
  const rolesSelected = useWatch({
    control,
    name: `roles`,
  })
  useEffect(() => {
    if (customers?.data) {
      setCustomersFormatted(
        formatEntityWithNameAndIdToAutocomplete(customers?.data),
      )
    }
  }, [customers])

  useEffect(() => {
    if (!isEmpty(roles)) {
      setRolesFormatted(formatRoleToAutocomplete(roles))
    }
  }, [roles])

  useEffect(() => {
    if (genders?.data) {
      setGendersFormatted(formatGenderToAutocomplete(genders?.data))
    }
  }, [genders])
  useEffect(() => {
    if (educationalLevel?.data) {
      setEducationLevelsFormatted(
        formatEducationLevelsToAutocomplete(educationalLevel?.data),
      )
    }
  }, [educationalLevel])
  useEffect(() => {
    if (towns?.data) {
      setTownsFormatted(formatTownsNameWithPostalCode(towns?.data))
    }
  }, [towns])

  useEffect(() => {
    refetchTowns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [townSearch])

  const onSubmit = async (values) => {
    await createUser.mutateAsync({
      data: values,
    })
    popSnackbar(t('user-create-success'), SUCCESS)
    queryClient.invalidateQueries(USERS)
    queryClient.invalidateQueries(USER)
    navigate(USERS_PATH)
  }

  return (
    <Box
      sx={{
        mt: 2,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'common.white',
        p: 2,
        borderRadius: (theme) => theme.shape.borderRadius,
      }}
    >
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button startIcon={<ArrowBack />} component={Link} to={USERS_PATH}>
          {t('back-to-users')}
        </Button>
      </Box>
      <Typography variant="h3" mb={2}>
        {t('add-user')}
      </Typography>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...form}>
        <Grid
          container
          spacing={2}
          component="form"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="firstName"
              label={t('firstname')}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="lastName"
              label={t('name')}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="email"
              label={t('email')}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledAutocomplete
              name="roles"
              multiple
              TextFieldProps={{ size: 'small', label: t('roles') }}
              loading={isLoadingRoles}
              options={rolesNotAlreadySelected}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="password"
              type="password"
              label={t('password')}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="passwordConfirm"
              type="password"
              label={t('confirm-password')}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledAutocomplete
              name="gender"
              TextFieldProps={{ size: 'small', label: t('gender') }}
              loading={isLoadingGenders}
              options={gendersFormatted}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledAutocomplete
              name="educationalLevel"
              TextFieldProps={{
                size: 'small',
                label: t('educational-level'),
              }}
              loading={isLoadingEducation}
              options={educationLevelsFormatted}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledAutocomplete
              name="town"
              TextFieldProps={{ size: 'small', label: t('localisation') }}
              loading={townsLoading}
              options={townsFormatted}
              onInputChange={(_, value) => {
                setTownSearch(value)
              }}
              filterOptions={(x) => x}
            />
          </Grid>
          {rolesSelected.some((r) => r.label === DefaultRole.CUSTOMER) && (
            <Grid item xs={12} md={6}>
              <ControlledAutocomplete
                name="customer"
                TextFieldProps={{ size: 'small', label: t('customer') }}
                loading={customersLoading}
                options={customersFormatted}
              />
            </Grid>
          )}
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button variant="contained" type="submit">
              {t('common-save')}
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  )
}

export default UsersForm
