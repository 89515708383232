import { Box, MenuItem } from '@mui/material'
import ControlledAutocomplete from 'components/molecules/ControlledAutocomplete'
import ControlledDatePicker from 'components/molecules/ControlledDatePicker'
import ControlledSelect from 'components/molecules/ControlledSelect'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { Operators } from 'enums/filters'
import { get, isEmpty, map } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useGridColumns from '../../hooks/useGridColumns'
import { FIELD_TYPE } from './enums'
import { Props } from './types'

function ToolbarFilterRowInput({ index }: Props) {
  const { t } = useTranslation()
  const {
    setValue,
    watch,
    formState: { isDirty },
  } = useFormContext()
  const columns = useGridColumns()
  const field = watch(`filters.${index}.field`)
  const op = watch(`filters.${index}.op`)
  const valueFilter = watch(`filters.${index}.value`)
  const valueFilterMultiple = watch(`filters.${index}.multipleValue`)
  const column = get(columns, field)
  const fieldType = column.type
  const valueOptions = column.valueOptions ?? []

  useEffect(() => {
    if ([Operators.NULL, Operators.NOT_NULL].includes(op)) {
      if (isDirty) {
        setValue(`filters.${index}.value`, '')
        setValue(`filters.${index}.multipleValue`, [])
      }
      setValue(`filters.${index}.valueLabel`, null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [op])

  useEffect(() => {
    if (isDirty) {
      setValue(`filters.${index}.value`, '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field])

  useEffect(() => {
    if (fieldType === FIELD_TYPE.SINGLE_SELECT) {
      const option = (valueOptions as any[]).find(
        (element) => element.value === valueFilter,
      )
      if (option) {
        setValue(`filters.${index}.valueLabel`, option.label)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFilter])

  useEffect(() => {
    if (
      fieldType === FIELD_TYPE.SINGLE_SELECT &&
      [Operators.IN, Operators.NOT_IN].includes(op)
    ) {
      const option = valueFilterMultiple.map(({ label }) => label).join(', ')

      if (option) {
        setValue(`filters.${index}.valueLabel`, option)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFilterMultiple])

  useEffect(() => {
    setValue(`filters.${index}.valueLabel`, null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field])

  useEffect(() => {
    if (fieldType === FIELD_TYPE.DATE) {
      setValue(
        `filters.${index}.valueLabel`,
        DateTime.fromISO(valueFilter).toLocaleString(DateTime.DATE_SHORT),
      )
    }
    if (fieldType === FIELD_TYPE.DATETIME) {
      setValue(
        `filters.${index}.valueLabel`,
        DateTime.fromISO(valueFilter).toLocaleString(DateTime.DATETIME_SHORT),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFilter])

  if ([Operators.NULL, Operators.NOT_NULL].includes(op)) {
    return null
  }

  switch (fieldType) {
    case FIELD_TYPE.STRING:
      return (
        <ControlledTextField
          sx={{ width: (theme) => theme.spacing(25) }}
          name={`filters.${index}.value`}
          label={t('value')}
          shrink
        />
      )
    case FIELD_TYPE.BOOL:
      return (
        <ControlledSelect
          sx={{ width: (theme) => theme.spacing(25) }}
          name={`filters.${index}.value`}
          label={t('value')}
        >
          <MenuItem value="true">{t('true')}</MenuItem>
          <MenuItem value="false">{t('false')}</MenuItem>
        </ControlledSelect>
      )
    case FIELD_TYPE.NUMBER:
      return (
        <ControlledTextField
          sx={{ width: (theme) => theme.spacing(25) }}
          name={`filters.${index}.value`}
          type="number"
          label={t('value')}
          shrink
        />
      )
    case FIELD_TYPE.DATE:
      return (
        <ControlledDatePicker
          sx={{ width: (theme) => theme.spacing(25) }}
          name={`filters.${index}.value`}
          label={t('value')}
        />
      )
    case FIELD_TYPE.DATETIME:
      return (
        <ControlledDatePicker
          sx={{ width: (theme) => theme.spacing(25) }}
          name={`filters.${index}.value`}
          type="datetime-local"
          label={t('value')}
        />
      )
    case FIELD_TYPE.SINGLE_SELECT:
      if ([Operators.IN, Operators.NOT_IN].includes(op)) {
        return (
          <Box sx={{ width: (theme) => theme.spacing(25) }}>
            <ControlledAutocomplete
              name={`filters.${index}.multipleValue`}
              multiple
              options={(valueOptions as any[]) || []}
              loading={isEmpty(valueOptions)}
              getOptionLabel={(option) => option?.label || ''}
              isOptionEqualToValue={(option, _value) =>
                option.value === _value.value
              }
            />
          </Box>
        )
      }
      return (
        <ControlledSelect
          sx={{ width: (theme) => theme.spacing(25) }}
          name={`filters.${index}.value`}
          label={t('value')}
        >
          {map(valueOptions, ({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </ControlledSelect>
      )

    default:
      return null
  }
}

export default ToolbarFilterRowInput
