import { Operators } from 'enums/filters'
import { get, toPairs, pickBy, first, isEmpty, filter } from 'lodash'
import { GridColType } from '@mui/x-data-grid'
import { getOperators } from './enums'

/**
 * [FTEL] Helper permettant de récupérer les valeurs de la cellule modifiée dans un handleRowUpdate de DataGrid
 * @param {object} next — Objet de la ligne avec les nouvelles valeurs
 * @param {object} prev — Objet de la ligne avec les anciennes valeurs
 * @returns {object} La cellule modifiée
 * @example { field: "nomDeLaColonne", value: 34 }
 */
export const getEditedPair = (next, prev): { field: string; value: any } => {
  const editedPairs = toPairs(
    pickBy(next, (value, key) => get(prev, key) !== value),
  )

  if (isEmpty(editedPairs)) {
    return null
  }

  const [field, value] = first(editedPairs)

  return { field, value }
}

export const getEligibleFields = (fields) => {
  return filter(fields, ({ filterable, type }) => {
    return filterable && !['actions'].includes(type)
  })
}

export const getEligibleOperators = (fieldType: GridColType, t) => {
  const operators = getOperators(t)
  switch (fieldType) {
    case 'string':
      return filter(operators, ({ value }) => {
        return [
          Operators.CONTAINS_I,
          Operators.NOT_CONTAINS_I,
          Operators.EQUAL_I,
          Operators.NOT_EQUAL,
          Operators.NULL,
          Operators.NOT_NULL,
          Operators.STARTS_WITH_I,
          Operators.ENDS_WITH_I,
        ].includes(value)
      })

    case 'number':
      return filter(operators, ({ value }) => {
        return [
          Operators.EQUAL,
          Operators.NOT_EQUAL,
          Operators.LESS_THAN,
          Operators.LESS_THAN_OR_EQUAL,
          Operators.GREATER_THAN,
          Operators.GREATER_THAN_OR_EQUAL,
          Operators.NULL,
          Operators.NOT_NULL,
        ].includes(value)
      })

    case 'date':
      return filter(operators, ({ value }) => {
        return [
          Operators.EQUAL,
          Operators.NOT_EQUAL,
          Operators.LESS_THAN,
          Operators.LESS_THAN_OR_EQUAL,
          Operators.GREATER_THAN,
          Operators.GREATER_THAN_OR_EQUAL,
          Operators.NULL,
          Operators.NOT_NULL,
        ].includes(value)
      })

    case 'dateTime':
      return filter(operators, ({ value }) => {
        return [
          Operators.EQUAL,
          Operators.NOT_EQUAL,
          Operators.LESS_THAN,
          Operators.LESS_THAN_OR_EQUAL,
          Operators.GREATER_THAN,
          Operators.GREATER_THAN_OR_EQUAL,
          Operators.NULL,
          Operators.NOT_NULL,
        ].includes(value)
      })

    case 'boolean':
      return filter(operators, ({ value }) => {
        return [Operators.EQUAL, Operators.NOT_EQUAL].includes(value)
      })

    case 'singleSelect':
      return filter(operators, ({ value }) => {
        return [
          Operators.NOT_IN,
          Operators.IN,
          Operators.NULL,
          Operators.NOT_NULL,
        ].includes(value)
      })

    default:
      return []
  }
}
