import { FormControl, FormHelperText, Switch, Typography } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ControlledSwitchProps } from './types'

function ControlledSwitch({
  name,
  control,
  label = '',
  ...props
}: ControlledSwitchProps) {
  const { t } = useTranslation()
  const controller = useController({ name, control })
  const {
    field: { value, ...field },
    fieldState: { error },
  } = controller

  if (value === undefined) {
    console.error(
      `[FTEL-ERROR] : Le composant ControlledSwitch '${name}' a une valeur undefined, il faut impérativement donner une valeur par défaut au formulaire (defaultValues)"`,
    )
  }

  return (
    <FormControl {...props} error={!!error}>
      <Typography variant="body2">{label}</Typography>
      <Switch checked={value} {...field} />

      {error && (
        <FormHelperText>
          {get(error, 'message', t('invalid-field'))}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default ControlledSwitch
