import { styled } from '@mui/material'

const ScrollShadowBox = styled('div')(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  background: `linear-gradient(${theme.palette.background.default} 33%, rgba(242,71,41, 0)),\n    linear-gradient(rgba(242,71,41, 0), ${theme.palette.background.default} 66%) 0 100%,\n    radial-gradient(farthest-side at 50% 0, rgba(34,34,34, 0.5), rgba(0,0,0,0)),\n    radial-gradient(farthest-side at 50% 100%, rgba(34,34,34, 0.5), rgba(0,0,0,0)) 0 100%`,
  backgroundColor: `${theme.palette.background.default}`,
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'local, local, scroll, scroll',
  backgroundSize: '100% 30px, 100% 30px, 100% 10px, 100% 10px',
}))

export default ScrollShadowBox
