import { ArrowBack } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'
import ControlledAutocomplete from 'components/molecules/ControlledAutocomplete'
import ControlledTextField from 'components/molecules/ControlledTextField'
import {
  formatTownNameWithPostalCode,
  formatTownsNameWithPostalCode,
} from 'components/organisms/RunsForm/formatters'
import useCreateUserForm from 'components/organisms/UsersForm/form'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useCustomersQuery } from 'domains/customers/queries'
import { formatEducationLevelsToAutocomplete } from 'domains/educationalLevels/formatters'
import { useEducationalLevelsQuery } from 'domains/educationalLevels/queries'
import { formatGenderToAutocomplete } from 'domains/gender/formatters'
import { useGendersQuery } from 'domains/gender/queries'
import { DefaultRole } from 'domains/roles/enums'
import { useRolesQuery } from 'domains/roles/queries'
import { useTownsQuery } from 'domains/towns/queries'
import { useUpdateUserMutation } from 'domains/users/mutations'
import { useUserQuery } from 'domains/users/queries'
import { USER, USERS } from 'domains/users/templates'
import { USERS_PATH } from 'enums/paths'
import { formatEntityWithNameAndIdToAutocomplete } from 'formatters/autocompleteFormatter'
import { formatPaginationToQueryString } from 'formatters/filters'
import { filter, find, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { FormProvider, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { formatRoleToAutocomplete } from '../../../../../domains/roles/formatters'
import { TOWNS_DEFAULT_PAGE, TOWNS_DEFAULT_PAGE_SIZE } from './constants'

function UsersEdit() {
  const { t } = useTranslation()
  const { id } = useParams()
  const { popSnackbar } = useSnackbar()
  const { data: user } = useUserQuery({ id })
  const form = useCreateUserForm({ variant: 'edit' })
  const { control } = form
  const updateUser = useUpdateUserMutation()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { data: roles, isLoading: isLoadingRoles } = useRolesQuery()
  const { data: genders, isLoading: isLoadingGenders } = useGendersQuery()
  const { data: customers, isLoading: customersLoading } = useCustomersQuery()
  const { data: educationalLevel, isLoading: isLoadingEducation } =
    useEducationalLevelsQuery()
  const [townSearch, setTownSearch] = React.useState('')
  const {
    data: towns,
    isLoading: townsLoading,
    refetch: refetchTowns,
  } = useTownsQuery(
    { search: townSearch },
    {},
    {
      pagination: formatPaginationToQueryString(
        TOWNS_DEFAULT_PAGE,
        TOWNS_DEFAULT_PAGE_SIZE,
      ),
    },
  )
  const [rolesFormatted, setRolesFormatted] = useState([])
  const [gendersFormatted, setGendersFormatted] = useState([])
  const [townsFormatted, setTownsFormatted] = useState([])
  const [educationLevelsFormatted, setEducationLevelsFormatted] = useState([])
  const [customersFormatted, setCustomersFormatted] = useState([])

  const rolesAlreadySelected = form.watch('roles')
  const rolesNotAlreadySelected = filter(
    rolesFormatted,
    (role) => !find(rolesAlreadySelected, ['id', role.id]),
  )

  useEffect(() => {
    if (towns?.data) {
      setTownsFormatted(formatTownsNameWithPostalCode(towns?.data))
    }
  }, [towns])

  useEffect(() => {
    refetchTowns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [townSearch])

  useEffect(
    function loadUserData() {
      if (!isEmpty(user)) {
        form.reset(user)
        const userRolesFormatted = []
        user.roles.map((role) =>
          userRolesFormatted.push({ label: role?.key, id: role?.id }),
        )
        form.setValue('roles', userRolesFormatted)
        if (user.gender) {
          form.setValue('gender', {
            label: user.gender?.name,
            id: user.gender?.id,
          })
        }
        if (user.town) {
          form.setValue('town', {
            label: formatTownNameWithPostalCode(user.town),
            id: user.town?.id,
          })
        }
        if (user.educationalLevel) {
          form.setValue('educationalLevel', {
            label: user.educationalLevel?.name,
            id: user.educationalLevel?.id,
          })
        }
        if (user.customer) {
          form.setValue('customer', {
            label: user.customer?.name,
            id: user.customer?.id,
          })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  )
  const rolesSelected = useWatch({
    control,
    name: `roles`,
  })
  useEffect(() => {
    if (customers?.data) {
      setCustomersFormatted(
        formatEntityWithNameAndIdToAutocomplete(customers?.data),
      )
    }
  }, [customers])
  useEffect(() => {
    if (!isEmpty(roles)) {
      setRolesFormatted(formatRoleToAutocomplete(roles))
    }
  }, [roles])

  useEffect(() => {
    if (genders?.data) {
      setGendersFormatted(formatGenderToAutocomplete(genders?.data))
    }
  }, [genders])

  useEffect(() => {
    if (educationalLevel?.data) {
      setEducationLevelsFormatted(
        formatEducationLevelsToAutocomplete(educationalLevel?.data),
      )
    }
  }, [educationalLevel])

  const onSubmit = async (values) => {
    await updateUser.mutateAsync({
      id,
      data: values,
    })
    popSnackbar(t('user-update-success'), SUCCESS)
    queryClient.invalidateQueries(USER)
    queryClient.invalidateQueries(USERS)
    navigate(USERS_PATH)
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        mt: 2,
      }}
    >
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button startIcon={<ArrowBack />} component={Link} to={USERS_PATH}>
          {t('back-to-users')}
        </Button>
      </Box>
      <Box
        sx={{
          p: 2,
          bgcolor: 'common.white',
          borderRadius: (theme) => theme.shape.borderRadius,
        }}
      >
        <Typography variant="h3" mb={2}>
          {t('update-user')}
        </Typography>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <FormProvider {...form}>
          <Grid
            container
            spacing={2}
            component="form"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="firstName"
                label={t('firstname')}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="lastName"
                label={t('name')}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="email"
                label={t('email')}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledAutocomplete
                name="roles"
                multiple
                TextFieldProps={{ size: 'small', label: t('roles') }}
                loading={isLoadingRoles}
                options={rolesNotAlreadySelected}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledAutocomplete
                name="gender"
                TextFieldProps={{ size: 'small', label: t('gender') }}
                loading={isLoadingGenders}
                options={gendersFormatted}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledAutocomplete
                name="educationalLevel"
                TextFieldProps={{
                  size: 'small',
                  label: t('educational-level'),
                }}
                loading={isLoadingEducation}
                options={educationLevelsFormatted}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledAutocomplete
                name="town"
                TextFieldProps={{ size: 'small', label: t('localisation') }}
                loading={townsLoading}
                options={townsFormatted}
                onInputChange={(_, value) => {
                  setTownSearch(value)
                }}
                filterOptions={(x) => x}
              />
            </Grid>

            {(rolesSelected.some((r) => r.label === DefaultRole.CUSTOMER) ||
              user?.customer) && (
              <Grid item xs={12} md={6}>
                <ControlledAutocomplete
                  name="customer"
                  TextFieldProps={{ size: 'small', label: t('customer') }}
                  loading={customersLoading}
                  options={customersFormatted}
                />
              </Grid>
            )}
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Button variant="contained" type="submit">
                {t('update')}
              </Button>
            </Grid>
          </Grid>
        </FormProvider>
      </Box>
    </Box>
  )
}

export default UsersEdit
