import { Add } from '@mui/icons-material'
import { Box } from '@mui/material'
import LinkButton from 'components/atoms/LinkButton'
import { EMAIL_MANAGEMENT_FUSION_FIELDS_CREATE_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'

function Toolbar() {
  const { t } = useTranslation()

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <LinkButton
        to={EMAIL_MANAGEMENT_FUSION_FIELDS_CREATE_PATH}
        variant="outlined"
        startIcon={<Add />}
      >
        {t('add-tag')}
      </LinkButton>
    </Box>
  )
}

export default Toolbar
