import { Box } from '@mui/material'
import { useQueryClient } from 'react-query'
import DataGrid from 'components/molecules/DataGrid'
import Header from 'components/molecules/Header'
import { useUserAnswersQuery } from 'domains/userAnswers/queries'
import { QUESTIONS_ANSWERS_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useUserAnswersGrid from './grid'

function QuestionsAnswersList() {
  const { t } = useTranslation()
  const { columns, defaultSort } = useUserAnswersGrid()
  const queryClient = useQueryClient()
  return (
    <>
      <Header title={t('questions-answers')} />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DataGrid
          id="questions-answers"
          getEntities={useUserAnswersQuery}
          sx={{ flex: 1 }}
          columns={columns}
          getRowId={(row) => row.id}
          componentsProps={{
            toolbar: {
              onRefresh: () => {
                queryClient.invalidateQueries({
                  queryKey: [QUESTIONS_ANSWERS_PATH],
                })
              },
            },
          }}
          defaultSort={defaultSort}
        />
      </Box>
    </>
  )
}

export default QuestionsAnswersList
