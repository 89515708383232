import { Button, Paper, Typography } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { ERROR, useSnackbar } from 'contexts/SnackbarContext'
import { useResetPasswordMutation } from 'domains/users/mutations'
import { HOME_PATH } from 'enums/paths'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import useLoginForm from './form'

function ResetPasswordForm() {
  const { t } = useTranslation()
  const form = useLoginForm()
  const params = useParams()
  const { key } = params
  const navigate = useNavigate()
  const resetPassword = useResetPasswordMutation()
  const { popSnackbar } = useSnackbar()

  const onSubmit = async (values) => {
    const data = { key, ...values }
    resetPassword.mutateAsync(
      {
        data,
      },
      {
        onSuccess: () => {
          navigate(HOME_PATH, { replace: true })
        },
        onError: () => {
          popSnackbar(t('error'), ERROR)
          navigate(HOME_PATH, { replace: true })
        },
      },
    )
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      <Paper
        component="form"
        onSubmit={form.handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          p: 2,
          width: 400,
        }}
      >
        <Typography
          className="animate__animated animate__headShake"
          color="primary"
          variant="h4"
          sx={{ alignSelf: 'center' }}
        >
          {t('reset-password')}
        </Typography>
        <ControlledTextField
          name="password"
          type="password"
          label={t('password')}
        />
        <ControlledTextField
          name="passwordCopy"
          type="password"
          autoComplete="new-password"
          label={t('confirm-password')}
        />
        <Button color="primary" type="submit">
          {t('change-password')}
        </Button>
      </Paper>
    </FormProvider>
  )
}

export default ResetPasswordForm
