import { DensityLarge, DensityMedium, DensitySmall } from '@mui/icons-material'
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  GridDensity,
  gridDensityValueSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { DensityMenuItem } from './types'

function ToolbarDensityButton() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const apiRef = useGridApiContext()
  const density = useGridSelector(apiRef, gridDensityValueSelector)
  const { t } = useTranslation()

  const getIcon = () => {
    switch (density) {
      case 'compact':
        return <DensitySmall />
      case 'standard':
        return <DensityMedium />
      case 'comfortable':
        return <DensityLarge />

      default:
        return null
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangeDensity = (nextDensity: GridDensity) => {
    apiRef.current.setDensity(nextDensity)
    handleClose()
  }

  const items: DensityMenuItem[] = [
    {
      value: 'compact',
      label: 'Compact',
      Icon: DensitySmall,
    },
    {
      value: 'standard',
      label: 'Standard',
      Icon: DensityMedium,
    },
    {
      value: 'comfortable',
      label: 'Confortable',
      Icon: DensityLarge,
    },
  ]

  return (
    <>
      <Tooltip title={t('density-lines')}>
        <IconButton onClick={handleClick}>{getIcon()}</IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items.map(({ value, label, Icon }) => (
          <MenuItem key={value} onClick={() => handleChangeDensity(value)}>
            <ListItemIcon>
              <Icon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default ToolbarDensityButton
