import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export const defaultValues = (nextIndex) => {
  return {
    name: '',
    index: nextIndex,
    description: '',
    picture: null,
  }
}

function useCreateLevelDefaultForm(nextIndex) {
  const { t } = useTranslation()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required-field')),
    description: Yup.string().required(t('required-field')),
    picture: Yup.object().required(t('required-field')).nullable(),
    index: Yup.number().min(1, t('index-sup-egal-1')).nullable(),
  })

  return useForm({
    defaultValues: defaultValues(nextIndex),
    resolver: yupResolver(validationSchema),
  })
}

export default useCreateLevelDefaultForm
