import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { LEVEL, LEVELS } from './templates'
import { Level } from './types'

export function useLevelsQuery(params?, options?, filtersPaginationAndSort?) {
  return useQueryWrapper<PaginatedType<Level>>(
    'GET',
    LEVELS,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function useLevelQuery(params?, options?) {
  return useQueryWrapper<Level>('GET', LEVEL, params, options)
}
