import { Run } from 'domains/runs/types'
import { Town } from 'domains/towns/types'
import _, { cloneDeep, get, isEmpty } from 'lodash'

export function formatRunToForm(run: Run) {
  const formatRun: any = cloneDeep(run)
  formatRun.town = isEmpty(run.customer)
    ? null
    : { id: run.town?.id, label: run.town?.name }
  formatRun.customer = isEmpty(run.customer)
    ? null
    : { id: run.customer?.id, label: run.customer?.name }
  const formattedThematics = []
  run.thematics.map((thematic) =>
    formattedThematics.push({ id: thematic.id, label: thematic.name }),
  )
  formatRun.thematics = formattedThematics
  const formattedPartners = []
  run.partners.map((partner) =>
    formattedPartners.push({ id: partner.id, label: partner.name }),
  )
  formatRun.partners = formattedPartners
  return formatRun
}

export function formatTownNameWithPostalCode(town: Town) {
  const name = get(town, 'name', '')
  const postalCode = get(town, 'postalCode', '')
  const formattedPostalCode = !_.isEmpty(postalCode) ? `(${postalCode})` : ''

  return `${name} ${formattedPostalCode}`
}

export function formatTownsNameWithPostalCode(collection: Town[]) {
  const collectionFormattedTemp = []
  if (!isEmpty(collection)) {
    collection.map((element) =>
      collectionFormattedTemp.push({
        label: formatTownNameWithPostalCode(element),
        id: get(element, 'id', null),
      }),
    )
  }
  return collectionFormattedTemp
}
