import { Skeleton } from '@mui/material'
import React from 'react'
import { MIN_HEIGHT_PARTNER_CARD } from './constants'

function PartnerCardSkeleton() {
  return (
    <Skeleton
      variant="rectangular"
      height={MIN_HEIGHT_PARTNER_CARD}
      width="100%"
    />
  )
}

export default PartnerCardSkeleton
