import { ViewColumn } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import {
  GridPreferencePanelsValue,
  gridPreferencePanelStateSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

function ToolbarColumnsButton(props) {
  const { onClick } = props
  const apiRef = useGridApiContext()
  const { t } = useTranslation()
  const { open, openedPanelValue } = useGridSelector(
    apiRef,
    gridPreferencePanelStateSelector,
  )

  const showColumns = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (open && openedPanelValue === GridPreferencePanelsValue.columns) {
      apiRef.current.hidePreferences()
    } else {
      apiRef.current.showPreferences(GridPreferencePanelsValue.columns)
    }

    onClick?.(event)
  }

  return (
    <Tooltip title={t('display-columns')}>
      <IconButton onClick={showColumns}>
        <ViewColumn />
      </IconButton>
    </Tooltip>
  )
}

export default ToolbarColumnsButton
