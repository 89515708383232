/* eslint-disable react/jsx-props-no-spreading */
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useUpdateThematicMutation } from 'domains/thematics/mutations'
import { THEMATIC } from 'domains/thematics/templates'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import useEditThematicForm from '../../forms/edit'
import Form from '../Form'

function EditForm({ thematic }) {
  const form = useEditThematicForm(thematic)
  const { t } = useTranslation()
  const updateThematic = useUpdateThematicMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const onSubmit = (values) => {
    updateThematic.mutate(
      { id: thematic.id, data: values },
      {
        onSuccess: ({ id }) => {
          popSnackbar(t('thematic-edit-success'), SUCCESS)
          queryClient.invalidateQueries([THEMATIC, { id: thematic.id }])
        },
      },
    )
  }
  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)} variant="edit" />
    </FormProvider>
  )
}

export default EditForm
