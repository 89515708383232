import {
  CircularProgress,
  TextField,
  Autocomplete,
  Typography,
} from '@mui/material'
import { get } from 'lodash'
import React, { forwardRef } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const ControlledAutocomplete = forwardRef((props, ref) => {
  const {
    name,
    control,
    loading,
    TextFieldProps = {},
    options = [],
    ...rest
  } = props
  const { t } = useTranslation()
  const controller = useController({ name, control })
  const {
    field,
    fieldState: { error },
  } = controller

  if (field.value === undefined) {
    console.error(
      `[FTEL-ERROR] : Le composant ControlledAutocomplete '${name}' a une valeur undefined, il faut impérativement donner une valeur par défaut au formulaire (defaultValues)"`,
    )
  }

  const { label, ...textFieldProps } = TextFieldProps

  return (
    <Autocomplete
      ref={ref}
      options={options}
      {...field}
      onChange={(e, newValue) => field.onChange(newValue)}
      renderInput={(params) => (
        <>
          <Typography variant="body2">{label}</Typography>
          <TextField
            {...params}
            error={!!error}
            helperText={error && get(error, 'message', t('invalid-field'))}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              ...params.inputProps,
              autoComplete: 'none',
            }}
            {...textFieldProps}
          />
        </>
      )}
      {...rest}
    />
  )
})

export default ControlledAutocomplete
