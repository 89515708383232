import { Box } from '@mui/material'
import LinkButton from 'components/atoms/LinkButton'
import LoginForm from 'components/organisms/LoginForm'
import { FORGOT_PASSWORD_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'

function Login() {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'background.default',
      }}
    >
      <LoginForm />
      <LinkButton
        variant="default"
        sx={{
          mt: 1,
          color: (theme) =>
            theme.palette.getContrastText(theme.palette.background.default),
        }}
        to={FORGOT_PASSWORD_PATH}
      >
        {t('forgot-password')}
      </LinkButton>
    </Box>
  )
}

export default Login
