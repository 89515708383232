import { Box, Button, Grid, Typography } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { ERROR, SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useGetMailFusionFieldsQuery } from 'domains/mailFusionFields/queries'
import { useCreateMailTemplateMutation } from 'domains/mailTemplates/mutations'
import { MAIL_TEMPLATES } from 'domains/mailTemplates/templates'
import { EMAIL_MANAGEMENT_TEMPLATES_PATH } from 'enums/paths'
import { formatValuesWithEditor } from 'helpers/formatter'
import { forEach, isEmpty, set } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import EmailEditor from 'react-email-editor'
import useCreateMailTemplateForm from './form'

export const CREATE = 'create'

function MailTemplateForm() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const form = useCreateMailTemplateForm()
  const createMailTemplateField = useCreateMailTemplateMutation()
  const { data: mailFusionFields } = useGetMailFusionFieldsQuery()
  const emailEditorRef = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  useEffect(function loadMailFusionFields() {
    if (loaded && !isEmpty(mailFusionFields?.data)) {
      const fusionsFields = {}
      forEach(mailFusionFields?.data ?? [], ({ description, key }) =>
        set(fusionsFields, key, { name: key, value: `{{${key}}}` }),
      )
      emailEditorRef.current.editor.setMergeTags(fusionsFields)
    }
  })

  const onSubmit = async (values) => {
    const editorExport = new Promise((resolve) => {
      emailEditorRef.current.editor.exportHtml((html) => {
        resolve(html)
      })
    })
    editorExport.then(({ design, html }: any) => {
      const valuesWithEditor = formatValuesWithEditor(values, design, html)
      return createMailTemplateField
        .mutateAsync(
          { data: valuesWithEditor },
          {
            onSuccess: () => {
              popSnackbar(t('mail-template-create-success'), SUCCESS)
              queryClient.invalidateQueries(MAIL_TEMPLATES)
            },
            onError: () => {
              popSnackbar(t('error'), ERROR)
            },
          },
        )
        .then(() => navigate(EMAIL_MANAGEMENT_TEMPLATES_PATH))
    })
  }

  return (
    <Box
      sx={{
        mt: 2,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'common.white',
        p: 2,
        borderRadius: (theme) => theme.shape.borderRadius,
      }}
    >
      <Typography variant="h3" mb={2}>
        {t('add-mail-template')}
      </Typography>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...form}>
        <Grid
          container
          spacing={2}
          component="form"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="key"
              label={t('key')}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="name"
              label={t('template-name')}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              name="subject"
              label={t('subject')}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <EmailEditor
              style={{ width: '100%' }}
              ref={emailEditorRef}
              onLoad={() => setLoaded(true)}
            />
          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button variant="contained" type="submit">
              {t('common-save')}
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  )
}

export default MailTemplateForm
